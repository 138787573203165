import React from "react";
import { makeStyles } from "@mui/styles";
import AppGrid from "components/Table/grid.jsx";
import { ReactComponent as DocCheck } from "assets/Recycle.svg";
import ContentHeader from "components/ContentHeader";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  gridContent: {
    flex: 1,
    margin: "32px",
  },
  clickableId: {
    cursor: "pointer",
  },
}));

const TableColumns = (navigate, classes) => [
  {
    headerName: "ID",
    field: "batchId",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <div
          onClick={() => navigate("/bulk-upload/list")}
          className={classes.clickableId}
        >
          {params.value}
        </div>
      );
    },
  },
  {
    headerName: "Upload Date",
    field: "dateOfUpload",
    flex: 1,
    sortable: false,
  },
  {
    headerName: "Type of Certificate",
    field: "certificateType",
    flex: 1,
    sortable: false,
  },
  {
    headerName: "Status",
    field: "status",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <div className="status-cell">
          {" "}
          {params.value === "Added to BlockChain" ? (
            <span className="status-active"></span>
          ) : (
            ""
          )}{" "}
          {params.value}
        </div>
      );
    },
  },
];

const TableData = [
  {
    id: 1,
    batchId: 1,
    dateOfUpload: "2001",
    certificateType: "12",
    status: "Added to BlockChain",
  },
  {
    id: 2,
    batchId: 2,
    dateOfUpload: "2002",
    certificateType: "123",
    status: "Added to BlockChain",
  },
  {
    id: 3,
    batchId: 3,
    dateOfUpload: "2003",
    certificateType: "23",
    status: "Added to BlockChain",
  },
  {
    id: 6,
    batchId: 6,
    dateOfUpload: "2006",
    certificateType: "44",
    status: "Added to BlockChain",
  },
  {
    id: 7,
    batchId: 1,
    dateOfUpload: "2001",
    certificateType: "12",
    status: "Added to BlockChain",
  },
  {
    id: 8,
    batchId: 2,
    dateOfUpload: "2002",
    certificateType: "123",
    status: "Added to BlockChain",
  },
  {
    id: 9,
    batchId: 3,
    dateOfUpload: "2003",
    certificateType: "23",
    status: "Added to BlockChain",
  },
  {
    id: 10,
    batchId: 4,
    dateOfUpload: "2004",
    certificateType: "13",
    status: "Added to BlockChain",
  },
  {
    id: 11,
    batchId: 5,
    dateOfUpload: "2005",
    certificateType: "22",
    status: "Added to BlockChain",
  },
  {
    id: 12,
    batchId: 6,
    dateOfUpload: "2006",
    certificateType: "44",
    status: "Added to BlockChain",
  },
];

const BatchList = () => {
  const classes = useStyles();

  let navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className={classes.container}>
      <ContentHeader
        showBackIcon
        HeaderIcon={DocCheck}
        title="Bulk Upload"
        handleBackButton={goBack}
      />
      <div className={classes.gridContent}>
        <AppGrid columns={TableColumns(navigate, classes)} rows={TableData} />
      </div>
    </div>
  );
};

export default BatchList;
