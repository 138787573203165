import React from "react";
import { withStyles } from "@mui/styles";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  gridPageSizeSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const styles = (theme) => ({
  container: { width: "100%", height: "80%" },
  dataGrid: {
    width: "100%",
  },
  footerContainer: {
    display: "flex",
    width: "100%",
    fontFamily: "sans-serif",
  },
  rowsPerPage: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    marginLeft: "24px",
  },
  pagination: {
    flex: 1,
  },
});

function CustomPagination(classes) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const rowsPerPage = useGridSelector(apiRef, gridPageSizeSelector);

  return (
    <div className={classes.footerContainer}>
      <div className={classes.rowsPerPage}>
        <FormControl size="small">
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={rowsPerPage}
            onChange={(event, value) => {
              apiRef.current.setPageSize(value?.props?.value);
            }}
            autoWidth
            label="Age"
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </FormControl>
        Items Per Page
      </div>
      <div className={classes.pagination}>
        <Pagination
          color="primary"
          shape="rounded"
          count={pageCount}
          page={page + 1}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
      </div>
    </div>
  );
}

class AppGrid extends React.Component {
  state = {
    pageSize: 5,
  };
  render() {
    const { rows, columns, classes, disablePagination } = this.props;
    const { pageSize } = this.state;
    return (
      <div className={classes.container}>
        <DataGrid
          className={classes.dataGrid}
          disableSelectionOnClick
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) =>
            this.setState({ pageSize: newPageSize })
          }
          disableColumnResize={true}
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          getRowClassName={(params) => {
            return params.id % 2 === 0 ? "even-row" : "odd-row";
          }}
          components={{
            Pagination: () =>
              disablePagination ? <></> : CustomPagination(classes),
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AppGrid);
