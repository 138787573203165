import { Component } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "../../style.css";

const TableData = (props) => {
  const navigate = useNavigate();
  const { tableHeaderDetails, tableRowDetails } = props;

  //  props.tableHeaderDetails.map((eachData) =>

  //

  const onClickUserForm = (eachData) => {
    navigate("/admin/user-request-form", { state: eachData });
  };
  return (
    <>
      <table id="usersTable" className="users-table w-100">
        <tr
          style={{
            backgroundColor: "#902A2BE0",
            color: "#FFFFFF",
            height: "40px",
          }}
        >
          {tableHeaderDetails.map((eachData) => (
            <th style={{ width: "25%", fontWeight: "400" }}>{eachData}</th>
          ))}
        </tr>
        {tableRowDetails.length !== 0 &&
          tableRowDetails.map((eachData, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: index % 2 !== 0 ? "#f7f2f2" : "",
                height: "50px",
                color: "#000000",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              <td style={{ cursor: "pointer", textTransform: "capitalize" }}>
                {eachData.name}
              </td>
              <td>
                {/* {eachData.createdAt}  */}
                {moment(eachData.createdAt).format("LLL")}
              </td>
              <td
                className="d-flex align-items-center justify-content-center"
                style={{ height: "50px", width: "100%" }}
              >
                <div
                  className="d-flex justify-content-center"
                  style={{
                    marginRight: "10px",
                    height: "8px",
                    width: "8px",
                    borderRadius: "50%",
                    backgroundColor:
                      (eachData.status === "BLOCKED" && "#9C4243") ||
                      (eachData.status === "ACTIVE" && "#377D22") ||
                      (eachData.status === "PENDING" && "#D3CF64"),
                  }}
                ></div>
                {eachData.status}
              </td>
              <td>
                <div className="d-flex justify-content-center align-items-center">
                  <span
                    onClick={() => onClickUserForm(eachData)}
                    data-hover="Submit"
                    // data="Delete" // we can put the action to reflect in UI
                    data={
                      (eachData.status === "BLOCKED" && "Approve") ||
                      (eachData.status === "ACTIVE" && "Block") ||
                      (eachData.status === "PENDING" && "Approve")
                    }
                    id="tableActionButton"
                    className="py-1 px-3"
                    style={{
                      width: "fit-content",
                      border: "1px solid #902A2B",
                      borderRadius: "25px",
                      color: "#902A2B",
                    }}
                  ></span>
                </div>
              </td>
            </tr>
          ))}
      </table>
      {tableRowDetails.length === 0 && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            width: "100% !important",
            backgroundColor: "#f7f2f2",
            height: "120px",
            color: "#000000",
            fontWeight: "500",
            fontSize: "20px",
          }}
        >
          No Data Available!
        </div>
      )}
    </>
  );
};

export default TableData;
