import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import AppCard from "components/AppCard";
import { Button } from "@mui/material";
import { FileDropComponent } from "components/FileDrop";
import { ReactComponent as CsvDocIcon } from "assets/CsvDoc.svg";
import Modal from "@mui/material/Modal";
import PaymentSummary from "../../Payment/Summary";
import { ReactComponent as Logo } from "assets/logo.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const useStyles = makeStyles((theme) => ({
  templateContainer: {
    width: "80%",
    height: "80%",
    margin: "auto",
  },
  downloadBox: {
    border: `2px dashed  #902A2B4D`,
    boxShadow: "0px 3px 6px #377D2221",
    borderRadius: "5px",
    width: "95%",
    height: "95%",
    margin: "auto",
    alignItems: "center",
    "& .file-drop-target": {
      height: "100% !important",
    },
  },
  uploadBtn: {
    color: `${theme.palette.text.white} !important`,
    background: `${theme.palette.primary.main} !important`,
    borderRadius: "6px",
    lineHeight: "normal !important",
    fontFamily: "sans-serif !important",
    textTransform: "initial",
    // marginTop: '28px !important'
  },
  dragDropText: {
    fontSize: "16px",
    color: `${theme.palette.primary.main} !important`,
    opacity: 0.8,
    margin: "24px auto 32px auto",
  },
  modalContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  modalSize: {
    width: "80%",
    height: "80%",
    backgroundColor: "white",
    margin: "auto",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    height: "64px",
    backgroundColor: "#902a2b",
  },
  modalLogo: {
    height: "48px",
  },
  modalTitle: {
    color: "white",
    fontSize: "18px",
    fontWeight: "bold",
  },
  closeIcon: {
    marginLeft: "auto",
    color: "white",
    cursor: "pointer",
  },
}));

const UploadCertificate = (props) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className={classes.templateContainer}>
      <AppCard
        containerStyle={{ width: "100%", height: "100%", display: "flex" }}
      >
        <div className={classes.downloadBox}>
          <FileDropComponent styles={{ height: "100%", width: "100%" }}>
            <div className={classes.combimedIcons}>
              <span className={classes.csvIcon}>
                <CsvDocIcon />
              </span>
            </div>
            <div className={classes.dragDropText}>
              {"Drag & Drop your Certificate Here."}
            </div>
            <Button
              variant="filled"
              className={classes.uploadBtn}
              onClick={() => setModalOpen(true)}
            >
              Upload File
            </Button>
          </FileDropComponent>
        </div>
      </AppCard>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.modalContainer}>
          <div className={classes.modalSize}>
            <div className={classes.modalHeader}>
              <Logo className={classes.modalLogo} />{" "}
              <span className={classes.modalTitle}>Certificate Portal</span>
              <span className={classes.closeIcon}>
                <CloseOutlinedIcon onClick={() => setModalOpen(false)} />
              </span>
            </div>
            <div className={classes.modalContent}>
              <PaymentSummary />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UploadCertificate;
