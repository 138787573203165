import React from "react";
import { makeStyles } from "@mui/styles";
import { ReactComponent as RecycleIcon } from "assets/Recycle.svg";
import { ReactComponent as RecycleSmall } from "assets/RecycleSmall.svg";
import ContentHeader from "components/ContentHeader";
import AppCard from "components/AppCard";
import { Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  templateContainer: {
    width: "80%",
    height: "80%",
    margin: "auto",
  },
  content: {
    padding: "40px",
    flex: 1,
  },
  details: {
    display: "flex",
  },
  transactionId: {
    color: "#902a2b",
    fontSize: 18,
    fontWeight: 500,
  },
  certId: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 600,
  },
  transDetails: {
    fontSize: "20px",
    textAlign: "left",
  },
  certDetails: {
    lineHeight: "50px",
    fontSize: "20px",
  },
  verifyImg: {
    "& img": {
      width: "50px",
    },
    marginRight: "12px",
  },
  recycleIcon: {
    width: "24px",
    height: "24px",
    backgroundColor: "#E3A0A1",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputField: {
    padding: "6px",
  },
  filledInput: {
    "& .MuiFilledInput-input": {
      padding: "6px !important",
      width: "100px",
    },
    "& .MuiFormControl-root": {
      width: "fit-content",
    },
    "& .MuiFilledInput-root": {
      width: "fit-content",
    },
  },
  formControl: {
    width: "fit-content",
    marginRight: "24px",
  },
  fieldsContainer: {
    display: "flex",
    paddingLeft: "40px",
  },
  field: { marginRight: "16px" },
  modifyTemplate: {
    "& button": {
      borderRadius: "16px",
    },
    marginTop: "60px",
    marginRight: "20px",
    textAlign: "right",
  },
  modifyField: {
    fontWeight: "bold",
    fontSize: "22px",
    marginTop: "50px",
    marginBottom: "30px",
    color: "#292828d9",
    textAlign: "left",
  },
}));

const ModifyCertificate = (props) => {
  const classes = useStyles();
  const certificateData = {
    id: 233213,
    org: "Nambikkai Inaiyam",
    transactionId: "23er445566667re4",
  };
  let navigate = useNavigate();
  const fields = ["Name", "D.O.B", "Serial No", "Document No"];
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className={classes.templateContainer}>
      <AppCard
        containerStyle={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ContentHeader
          title="Modifying A Certificate"
          HeaderIcon={RecycleIcon}
          showBackIcon
          handleBackButton={goBack}
        />
        <div className={classes.content}>
          <div className={classes.details}>
            <div className={classes.verifyImg}>
              <img src="verified-icon.png" alt="img" />
            </div>

            <div>
              <div className={classes.certDetails}>
                This Certificates Belong to Certificate ID{" "}
                <span className={classes.certId}>{certificateData.id} </span>
                is Verified and Added to {certificateData.org}.
              </div>
              <div className={classes.transDetails}>
                <span>Transaction ID:</span>{" "}
                <span className={classes.transactionId}>
                  {certificateData.transactionId}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className={classes.modifyField}>Modify Fields:</div>
            <div className={classes.fieldsContainer}>
              {fields.map((fld) => (
                <div className={classes.field}>
                  <FormControl
                    // sx={{ m: 1, width: "25ch" }}
                    variant="filled"
                    className={classes.formControl}
                  >
                    <FilledInput
                      className={classes.filledInput}
                      hiddenLabel
                      disableUnderline
                      value={fld}
                      id="filled-adornment-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {}}
                            onMouseDown={() => {}}
                            edge="end"
                          >
                            <span className={classes.recycleIcon}>
                              <RecycleSmall />
                            </span>
                          </IconButton>
                        </InputAdornment>
                      }
                      classes={{
                        input: classes.inputField,
                      }}
                    />
                  </FormControl>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.modifyTemplate}>
            <Button variant="outlined" color="primary">
              Modify Template
            </Button>
          </div>
        </div>
      </AppCard>
    </div>
  );
};

export default ModifyCertificate;
