import {
  POST_LOGIN_ERR,POST_LOGIN,VERIFY_OTP_ERR,VERIFY_OTP,GET_ALL_USERS_ADMIN,GET_STACK_DATA,GET_COUNT_ADMIN,GET_GRAPH_DATA,GET_FIRST_GRAPH_DATA,GET_COUNT_DATA,GET_COUNT_VERIFIED
  ,GET_ALL_USERS, GET_ALL_BLOCKED_USERS, GET_TOTAL_USER_COUNT,BLOCK_USER,BLOCK_USER_ERR,GET_REVOKE_USERS
} from "./types";
  const initialState = {
    loginData_response: "",
    loginData_err:"",
    otp_response: "",
    otp_err:"",
    all_users:"",
    blocked_users:"",
    total_user_count:"",
    block_user:"",
    blockuserErr:"",
    revUser:"",
  };
  
  export default function reducer(state = initialState, { type, payload }) {
    switch (type) {

      case GET_ALL_USERS:
        return{...state, all_users:payload}
    case GET_ALL_BLOCKED_USERS:
        return {...state, blocked_users:payload}
    case GET_TOTAL_USER_COUNT:
        return {...state, total_user_count:payload}
    case BLOCK_USER:
        return {...state, block_user:payload}
    case BLOCK_USER_ERR:
        return {...state,blockuserErr:payload}    
    case GET_REVOKE_USERS:
          return{...state, revUser:payload}


      case POST_LOGIN:
        return {
          ...state,
          loginData_response: payload,
        };
      case POST_LOGIN_ERR:
        return {
          ...state,
          loginData_err: payload,
        };
        case VERIFY_OTP:
        return {
          ...state,
          otp_response: payload,
        };
      case VERIFY_OTP_ERR:
        return {
          ...state,
          otp_err: payload,
        };

    case GET_STACK_DATA:
      return {
        ...state,
        stackData: payload,
      }
    case GET_COUNT_VERIFIED:
      return {
        ...state,
        VerCount: payload,
      }
    case GET_COUNT_DATA:{
      console.log(payload)
      return {
        ...state,
        countValueData: payload,
      }}
    case GET_FIRST_GRAPH_DATA:{
      console.log(payload)
      return {
        ...state,
        firstGraphData: payload,
      }}
    case GET_GRAPH_DATA:
      return {
        ...state,
        graphData: payload,
      }
    case GET_COUNT_ADMIN:
      return {
        ...state,
        countData: payload,
      }
    case GET_ALL_USERS_ADMIN:
      return {
        ...state,
        allUser: payload,
      }
      default:
        return {
          ...state,
        };
    }
  }
  