import { Component } from "react";
import { FaUserCheck } from "react-icons/fa";
import { Doughnut } from "react-chartjs-2";
import TableData from "./TableData";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getAllUserAdmin } from "../../store/Admin/action";

class Users extends Component {
  state = {
    activeTab: "first",
    allUsersData: [],
    blockedUserData: [],
    pendingUserData: [],
  };

  componentDidMount() {
    this.props.getAllUserAdmin();
  }

  componentDidUpdate(prev) {
    if (prev.AllUserData !== this.props.AllUserData) {
      let blockedUsers = [];
      let pendingUsers = [];
      if (this.props.AllUserData.length !== 0) {
        blockedUsers = this.props.AllUserData.users.filter(
          (eachData) => eachData.status === "BLOCKED"
        );
        pendingUsers = this.props.AllUserData.users.filter(
          (eachData) => eachData.status === "PENDING"
        );
      }
      this.setState({
        allUsersData: this.props.AllUserData.users,
        blockedUserData: blockedUsers,
        pendingUserData: pendingUsers,
      });
    }
  }

  onClickActiveTab = (data) => {
    this.setState({ activeTab: data });
  };

  render() {
    const pieData = {
      datasets: [
        {
          data: [
            this.state.allUsersData.length,
            this.state.blockedUserData.length,
          ],
          backgroundColor: ["#BFDDA6", "#F68D88"],
        },
      ],
    };
    const allUsersTableHeader = [
      "User Name",
      "Signed In On",
      "Status",
      "Action",
    ];
    const blockedusersTableHeader = [
      "User ID",
      "Signed In On",
      "Status",
      "Action",
    ];
    const userRequestTableHeader = [
      "User ID",
      "Signed In On",
      "Status",
      "Action",
    ];
    const tableData = [
      {
        userName: "VIT University",
        signedInOn: "12/11/2021",
        status: "BLOCKED",
        action: "Delete",
      },
      {
        userName: "KVT Institutions",
        signedInOn: "12/11/2021",
        status: "ACTIVE",
        action: "Delete",
      },
      {
        userName: "PSG Institutions",
        signedInOn: "12/11/2021",
        status: "PENDING",
        action: "Delete",
      },
      {
        userName: "SRI KRISHNA Inst",
        signedInOn: "12/11/2021",
        status: "ACTIVE",
        action: "Approve",
      },
      {
        userName: "KG Inst pvt Ltd",
        signedInOn: "12/11/2021",
        status: "BLOCKED",
        action: "Delete",
      },
      {
        userName: "KVT Institutions",
        signedInOn: "12/11/2021",
        status: "PENDING",
        action: "Delete",
      },
    ];

    return (
      <div style={{ backgroundColor: "#F7F7F7", paddingBottom: "50px" }}>
        <div style={{ margin: "10px 30px" }}>
          <div
            className="w-100 pb-2 my-3"
            style={{
              borderRadius: "5px",
              boxShadow: "0px 3px 6px #00000029",
              backgroundColor: "#FFFFFF",
            }}
          >
            <span
              className="d-flex align-items-center gap-3 px-4 py-1"
              style={{ fontWeight: "500" }}
            >
              <FaUserCheck style={{ color: "#902A2B", fontSize: "20px" }} />{" "}
              Users
            </span>
            <hr
              className="my-2"
              style={{ border: "1px solid #00000029", color: "#00000029" }}
            />
            <div className="d-flex flex-wrap" style={{ padding: "10px 5%" }}>
              <div
                className="d-flex flex-grow-1 justify-content-around align-items-center"
                style={{ height: "200px" }}
              >
                <div className="d-flex flex-column">
                  <span
                    style={{
                      fontWeight: "400",
                      color: "#656565",
                      fontSize: "16px",
                    }}
                  >
                    Total Users
                  </span>
                  <span
                    style={{
                      fontWeight: "400",
                      color: "#4D87C9",
                      fontSize: "30px",
                    }}
                  >
                    {this.state.allUsersData.length}
                  </span>
                </div>

                <div className="d-flex flex-column">
                  <span
                    style={{
                      fontWeight: "400",
                      color: "#656565",
                      fontSize: "16px",
                    }}
                  >
                    Blocked Users
                  </span>
                  <span
                    style={{
                      fontWeight: "400",
                      color: "#F68D88",
                      fontSize: "30px",
                    }}
                  >
                    {this.state.blockedUserData.length}
                  </span>
                </div>
              </div>
              <div
                className="d-flex flex-column"
                style={{
                  borderRadius: "5px",
                  boxShadow: "0px 3px 6px #00000029",
                  minWidth: "375px",
                  width: "500px",
                }}
              >
                <span
                  style={{
                    fontWeight: "600",
                    color: "#000000",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  All Users
                </span>
                <div className="d-flex h-100 p-2">
                  <div className="w-50 d-flex justify-content-center align-items-center">
                    {" "}
                    <Doughnut
                      data={pieData}
                      options={{
                        responsive: true,
                        maintainAspectRatio: true,
                        legend: {
                          display: false,
                        },
                      }}
                    ></Doughnut>
                  </div>
                  <div className="w-50 d-flex flex-column justify-content-evenly">
                    <div
                      className="d-flex gap-3 align-items-center"
                      style={{
                        fontWeight: "600",
                        color: "#000000",
                        fontSize: "14px",
                        marginLeft: "50px",
                      }}
                    >
                      <div
                        style={{
                          height: "25px",
                          width: "35px",
                          borderRadius: "5px",
                          backgroundColor: "#BFDDA6",
                        }}
                      ></div>
                      All Users
                    </div>
                    <div
                      className="d-flex gap-3 align-items-center"
                      style={{
                        fontWeight: "600",
                        color: "#000000",
                        fontSize: "14px",
                        marginLeft: "50px",
                      }}
                    >
                      <div
                        style={{
                          height: "25px",
                          width: "35px",
                          borderRadius: "5px",
                          backgroundColor: "#F68D88",
                        }}
                      ></div>
                      Blocked Users
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="w-100 pb-2 mb-2"
            style={{
              borderRadius: "5px",
              boxShadow: "0px 3px 6px #00000029",
              backgroundColor: "#FFFFFF",
            }}
          >
            <div className="d-flex gap-5 pt-2 px-5">
              <span
                id={this.state.activeTab !== "first" && "activeTabUnderline"}
                onClick={() => this.onClickActiveTab("first")}
                className="d-flex align-items-center gap-3 px-2 py-1"
                style={{
                  fontWeight: "500",
                  cursor: "pointer",
                  color:
                    this.state.activeTab === "first"
                      ? "#000000D9"
                      : "#00000081",
                  borderBottom:
                    this.state.activeTab === "first"
                      ? "2px solid #902A2B"
                      : "none",
                }}
              >
                All Users
              </span>
              <span
                id={this.state.activeTab !== "second" && "activeTabUnderline"}
                onClick={() => this.onClickActiveTab("second")}
                className="d-flex align-items-center gap-3 px-2 py-1"
                style={{
                  fontWeight: "500",
                  cursor: "pointer",
                  color:
                    this.state.activeTab === "second"
                      ? "#000000D9"
                      : "#00000081",
                  borderBottom:
                    this.state.activeTab === "second"
                      ? "2px solid #902A2B"
                      : "none",
                }}
              >
                Blocked Users
              </span>
              <span
                id={this.state.activeTab !== "third" && "activeTabUnderline"}
                onClick={() => this.onClickActiveTab("third")}
                className="d-flex align-items-center gap-3 px-2 py-1"
                style={{
                  fontWeight: "500",
                  cursor: "pointer",
                  color:
                    this.state.activeTab === "third"
                      ? "#000000D9"
                      : "#00000081",
                  borderBottom:
                    this.state.activeTab === "third"
                      ? "2px solid #902A2B"
                      : "none",
                }}
              >
                User Request
              </span>
            </div>
            <hr
              className="my-2"
              style={{
                border: "1px solid #00000029",
                color: "#00000029",
                position: "relative",
                bottom: "10px",
              }}
            />
            <div style={{ padding: "0 30px" }}>
              {this.state.activeTab === "first" && (
                <TableData
                  tableHeaderDetails={allUsersTableHeader}
                  tableRowDetails={this.state.allUsersData}
                />
              )}
              {this.state.activeTab === "second" && (
                <TableData
                  tableHeaderDetails={allUsersTableHeader}
                  tableRowDetails={this.state.blockedUserData}
                />
              )}
              {this.state.activeTab === "third" && (
                <TableData
                  tableHeaderDetails={allUsersTableHeader}
                  tableRowDetails={this.state.pendingUserData}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AllUserData: state.Admin.getAllUserAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllUserAdmin,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Users);
