import { Component, useState } from "react";
import { AiOutlineFileExclamation } from "react-icons/ai";
import "../../style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserAdmin,
  updateApprovedUser,
  updateBlockedUser,
} from "store/action";
import { useEffect } from "react";

const UserRequestForm = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const getUserData = useSelector((state) =>

  const [inputFields, setInputFields] = useState([
    {
      label: "Enter Your Name",
      inputValue: location.state.name,
      inputPlaceholder: "Name",
      type: "text",
    },
    {
      label: "Enter Institution Name",
      inputValue: location.state.nameofinstitute,
      inputPlaceholder: "Select Your University",
      type: "text",
    },
    {
      label: "Enter Your Mail Address",
      inputValue: location.state.email,
      inputPlaceholder: "XXXXXXXXX@.com",
      type: "email",
    },
    {
      label: "Enter Your Mobile Number",
      inputValue: location.state.mobileno,
      inputPlaceholder: "9878675756",
      type: "number",
    },
  ]);

  // useEffect(() => {
  //   dispatch(getUserAdmin(location.state._id));
  // }, []);

  const onClickAction = () => {
    const body = { id: location.state._id };
    //
    if (location.state.status === "ACTIVE") {
      dispatch(updateBlockedUser(body));
    }
    if (
      location.state.status === "BLOCKED" ||
      location.state.status === "PENDING"
    ) {
      dispatch(updateApprovedUser(body));
    }
    // navigate("/admin/users")
  };

  const onClickBack = () => {
    navigate(-1);
  };

  return (
    <div className="my-5 mx-5" style={{ minHeight: "90vh" }}>
      <div className="" style={{ margin: "2% 4%" }}>
        <h5 className="d-flex justify-content-start align-items-center gap-3">
          <AiOutlineFileExclamation
            style={{ color: "#902A2B", fontSize: "26px" }}
          />{" "}
          User Request form
        </h5>
      </div>

      <div className="d-flex flex-wrap " style={{ margin: "2% 10%" }}>
        {inputFields.map((eachData, index) => (
          <div
            key={index}
            className="d-flex flex-column justify-content-center col-12 col-lg-6"
          >
            <p
              className="m-1"
              style={{
                textAlign: "left",
                color: "#292828D8",
                fontWeight: "500",
              }}
            >
              {eachData.label}
            </p>
            <input
              readOnly
              value={eachData.inputValue}
              className="m-1 p-2"
              placeholder={eachData.inputPlaceholder}
              style={{
                backgroundColor: "#F7FAFC",
                borderRadius: "11px",
                border: "none",
                minWidth: "375px",
                width: "90%",
              }}
            />
          </div>
        ))}
      </div>

      <div className="d-flex justify-content-end" style={{ margin: "2% 10%" }}>
        <button
          // onClick={() => (window.location.pathname = "/admin/users")}
          onClick={onClickBack}
          id="tableActionButton"
          style={{
            border: "1px solid #902A2B",
            borderRadius: "25px",
            outline: "none",
            fontWeight: "500",
            color: "#902A2B",
            padding: "2px 10px",
            backgroundColor: "white",
            marginRight: "20px",
          }}
        >
          Back
        </button>
        <button
          onClick={onClickAction}
          id="tableActionButton"
          style={{
            border: "1px solid #902A2B",
            borderRadius: "25px",
            outline: "none",
            fontWeight: "500",
            color: "#902A2B",
            padding: "2px 10px",
            backgroundColor: "white",
          }}
        >
          {location.state.status === "PENDING" && "Approve"}
          {location.state.status === "BLOCKED" && "Approve"}
          {location.state.status === "ACTIVE" && "Block"}
        </button>
      </div>
    </div>
  );
};

// function WithNavigate(props) {
//   let navigate = useNavigate();
//   const location = useLocation();
//   return <UserRequestForm {...props} navigate={navigate} location={location} />;
// }

// export default WithNavigate;

export default UserRequestForm;
