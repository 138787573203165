import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = createTheme({
	spacing: 4,
	typography: {
		fontFamily: [ 'Roboto', 'Raleway', 'Open Sans' ].join(','),
		h1: {
			fontSize: '5rem',
			fontFamily: 'Raleway'
		},
		h2: {
			fontSize: '3.5rem',
			fontFamily: 'Open Sans',
			fontStyle: 'bold'
		},
		h3: {
			fontSize: '2.5rem',
			fontFamily: 'Roboto'
		}
	},
	palette: {
		background: {
			default: '#009900' //green
		},
		primary: {
			main: '#902a2b' //indigo
		},
		secondary: {
			main: '#E769A6' //pink
		},
		error: {
			main: '#D72A2A' //red
		},
		warning: {
			main: '#FC7B09' //orange
		},
		info: {
			main: '#6B7D6A' //gray
		},
		success: {
			main: '#09FE00' //green
		},
		text: {
			primary: '#292828d9', //
			secondary: '#000000', //black
			white: '#FFFFFF' //white
		},
		grey: {
			'100': '#707070'
		}
	},
	overrides: {
		MuiDataGrid: {
			columnHeaders: {
				backgroundColor: 'lightgray',
				'& > th ': {
					color: 'black',
					fontWeight: 'bold'
				}
			}
		}
	}
});

export default theme;
