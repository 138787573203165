import { borderRadius } from "@mui/system";
import MainHeader from "components/MainHeader";
import { CgLogIn } from "react-icons/cg";
import "../../style.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getRegisterUser } from "store/action";
import * as CryptoJS from "crypto-js";

import footLogo from "../../assets/Nambikkai iniyam logo.png";
import niIcon from "../../assets/ni-icon.png";

const CreateAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [institutionName, setInstitutionName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNum, setMobileNum] = useState("");
  const [educationType, setEducationType] = "";

  const encapsulateData = (encapData) => {
    const encryptconfigs = {
      key: "t700#zkrF@db0705",
      iv: "i700#zkrF@db0705",
    };

    var key = CryptoJS.enc.Latin1.parse(encryptconfigs.key);
    var iv = CryptoJS.enc.Latin1.parse(encryptconfigs.iv);
    var encryptedData = CryptoJS.AES.encrypt(encapData, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    encryptedData = encryptedData.toString();
    //
    return encryptedData;
  };

  const onChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    //

    if (name === "institutionName") {
      setInstitutionName(value.replace(/[^A-Za-z0-9- ]/gi, ""));
    }
    if (name === "name") {
      setName(value.replace(/[^A-Za-z]/gi, ""));
    }
    if (name === "email") {
      setEmail(value);
    }
    if (name === "mobileNum") {
      setMobileNum(value.replace(/[^0-9]/gi, ""));
    }
    if (name === "educationType") {
      setEducationType(value);
    }
  };

  const onClickSubmit = (event) => {
    event.preventDefault();
    const body = {
      name: encapsulateData(name),
      nameofinstitute: encapsulateData(institutionName),
      email: encapsulateData(email),
      mobileno: encapsulateData(mobileNum),
      educationType: encapsulateData(educationType),
    };

    dispatch(getRegisterUser(body));
  };

  return (
    <form
      onSubmit={onClickSubmit}
      className="w-100"
      style={{ padding: "3% 7% 1% 7%" }}
    >
      <div className="d-flex justify-content-end w-100">
        <div
          className="px-3 py-5"
          style={{
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "11px",
            minWidth: "375px",
            width: "33%",
            borderBottom: "4px solid #902A2B",
          }}
        >
          <h5 className="d-flex align-items-center gap-3">
            <span>
              <CgLogIn
                style={{
                  fontSize: "26px",
                  color: "#902A2B",
                  fontWeight: "700",
                }}
              />
            </span>
            Create a new account
          </h5>
          <div className="d-flex flex-column gap-1" style={{ margin: "30px" }}>
            <div className="d-flex flex-column text-left">
              <p
                className="m-1"
                style={{
                  textAlign: "left",
                  color: "#292828D8",
                  fontWeight: "500",
                }}
              >
                Enter Institution Name
              </p>
              <input
                value={institutionName}
                required
                type="text"
                name="institutionName"
                onChange={onChangeInput}
                className="m-1 p-2"
                placeholder="Enter Your University"
                style={{
                  backgroundColor: "#F7FAFC",
                  borderRadius: "11px",
                  border: "none",
                }}
              />
            </div>
            {/* education option */}
            <div className="d-flex flex-column text-left">
              <p
                className="m-1"
                style={{
                  textAlign: "left",
                  color: "#292828D8",
                  fontWeight: "500",
                }}
              >
                Type of Degree Issued
              </p>
              <select
                required
                className="m-1 p-2"
                name="educationType"
                style={{
                  backgroundColor: "#F7FAFC",
                  borderRadius: "11px",
                  border: "none",
                  outline: "none",
                  height: "50px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
                // onChange={onChangeInput}
              >
                <option
                  style={{ fontSize: "14px", fontWeight: "500" }}
                  value=""
                  selected
                >
                  Select Option
                </option>

                <option
                  style={{ fontSize: "14px", fontWeight: "500" }}
                  value="Engineering Studies"
                >
                  1. Engineering Studies
                </option>
                <option
                  style={{ fontSize: "14px", fontWeight: "500" }}
                  value="Medical Studies"
                >
                  2. Medical Studies
                </option>
                <option
                  style={{ fontSize: "14px", fontWeight: "500" }}
                  value="Bachelor of Arts & Science"
                >
                  3. Bachelor of Arts & Science
                </option>
              </select>
            </div>
            {/* education option */}

            <div className="d-flex flex-column text-left">
              <p
                className="m-1"
                style={{
                  textAlign: "left",
                  color: "#292828D8",
                  fontWeight: "500",
                }}
              >
                Enter your Name
              </p>

              <input
                // onKeyDown={(event) => /[a-zA-Z ]*/i.test(event.key)}
                value={name}
                pattern="[a-zA-Z ]*"
                title="Please enter a valid name!"
                required
                type="text"
                name="name"
                onChange={onChangeInput}
                className="m-1 p-2"
                placeholder="Enter Your Name"
                style={{
                  backgroundColor: "#F7FAFC",
                  borderRadius: "11px",
                  border: "none",
                }}
              />
            </div>

            <div className="d-flex flex-column text-left">
              <p
                className="m-1"
                style={{
                  textAlign: "left",
                  color: "#292828D8",
                  fontWeight: "500",
                }}
              >
                Enter Your Mail ID
              </p>
              <input
                title="Please enter valid email!"
                required
                type="email"
                name="email"
                onChange={onChangeInput}
                className="m-1 p-2"
                // placeholder="XXXXXXXXX@.com"
                placeholder="Ex - abc@gmail.com"
                style={{
                  backgroundColor: "#F7FAFC",
                  borderRadius: "11px",
                  border: "none",
                }}
              />
            </div>

            <div className="d-flex flex-column text-left">
              <p
                className="m-1"
                style={{
                  textAlign: "left",
                  color: "#292828D8",
                  fontWeight: "500",
                }}
              >
                Enter Your Mobile Number
              </p>
              <input
                value={mobileNum}
                required
                type="tel"
                title="Please enter your 10 digit mobile number!"
                pattern="[1-9]{1}[0-9]{9}"
                name="mobileNum"
                onChange={onChangeInput}
                className="m-1 p-2"
                placeholder="Ex - 9874563210"
                style={{
                  backgroundColor: "#F7FAFC",
                  borderRadius: "11px",
                  border: "none",
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <span
              className="m-1"
              style={{
                textAlign: "left",
                color: "#902A2B",
                fontWeight: "500",
                fontSize: "14px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              Go back to sign in page?
            </span>
            <button
              className="px-3 py-2"
              style={{
                backgroundColor: "#902A2B",
                fontWeight: "500",
                fontSize: "14px",
                color: "#FFFFFF",
                border: "none",
                borderRadius: "25px",
              }}
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <span
          className="m-4"
          style={{ color: "#4984CA", fontWeight: "400", fontSize: "12px" }}
        >
          Powered By Nambikkai Inaiyam
        </span>
        <img src={niIcon} style={{ width: "20px" }} />
      </div>
    </form>
  );
};

export default CreateAccount;
