// import { devUrl, BASE_URL } from '../../utilities/config'
import {
  FORGOT_PASSWORD,
  GET_ALL_USER,
  GET_ALL_USER_ADMIN,
  GET_ALL_USER_ERROR,
  GET_REQUEST_USER_FORM,
  LOGIN,
  OTP_VERIFICATION,
  REGISTER_USER,
  RESEND_PASSWORD,
  RESET_PASSWORD,
  UPDATE_USER_APPROVED,
  UPDATE_USER_BLOCKED,
  UPDATE_USER_DATA,
  UPDATE_USER_DATA_ERR,
  VERIFY_FORGOT_PASSWORD_OTP,
} from "./types";
import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { BASE_URL } from "utilities/config";
// import { useNavigate } from "react-router-dom";
// import { dispatch } from 'd3'
// const MySwal = withReactContent(Swal);

/* AXIOS INTERCEPTOR */
axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 502) {
      window.location.href = "/service-unavailable";
    }
    return Promise.reject(err);
  }
);

let authtoken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyNTU0ODBmNGE5MTJiNzM3ZjliMTZiNiIsInVzZXJuYW1lIjoiYWRtaW4iLCJyb2xlIjoiYWRtaW4iLCJleHAiOjE2NTI3NjAyMTEsImlhdCI6MTY1Mjc1NjYxMX0.8cKfv0RM1z28vRju8L-G433K8c16yYtcHmc-l3l2HA4";

// const devURLTnega = BASE_URL;

export const getAllUser = (data) => (dispatch) => {
  const API_URL = `${BASE_URL}cportal/dashboard/users`;

  axios
    .get(API_URL, {
      headers: {
        Authorization: "Bearer " + authtoken, //localStorage.getItem('auth_token')
      },
    })
    .then((res) => {
      dispatch({ type: GET_ALL_USER, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: GET_ALL_USER_ERROR, payload: err.response.data });
    });
};

export const updateUser = (data) => (dispatch) => {
  const API_URL = `${BASE_URL}cportal/dashboard/userstatus`;

  axios
    .post(API_URL, data, {
      headers: {
        Authorization: "Bearer " + authtoken, //localStorage.getItem('auth_token')
      },
    })
    .then((res) => {
      dispatch({ type: UPDATE_USER_DATA, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: UPDATE_USER_DATA_ERR, payload: err.response.data });
    });
};

// ********* new api's ********* //

export const getLogin = (data, setOtpVerificationStatus) => (dispatch) => {
  const API_URL = `${BASE_URL}cportal/user/login`;

  axios
    .post(API_URL, data)
    .then((res) => {
      dispatch({ type: LOGIN, payload: res });

      if (res.status === 200) {
        if (res.data.status === true) {
          setOtpVerificationStatus((prev) => !prev);
        }

        // if (res.data.user.role === "admin") {
        //   // window.location.pathname = "admin/users";
        // }
      }
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Something went wrong!",
        // text: `Email or Password: is invalid`,
        text: err.response.data.message,
      });

      dispatch({ type: LOGIN, payload: err.response });
    });
};

export const getOtpStatus = (data, setOtpVerificationStatus) => (dispatch) => {
  const API_URL = `${BASE_URL}cportal/user/verifyotp`;

  axios
    .post(API_URL, data)
    .then((res) => {
      dispatch({ type: OTP_VERIFICATION, payload: res });
      // const navigate = useNavigate();

      if (res.status === 200) {
        if (res.data.status === true) {
          setOtpVerificationStatus((prev) => !prev);
          sessionStorage.setItem("token", res.data.user.token);
          sessionStorage.setItem("refreshToken", res.data.user.refreshToken);
          sessionStorage.setItem("role", res.data.user.role);
          if (res.data.user.role === "ADMIN") {
            window.location.pathname = "/admin/users";
          }
          if (res.data.user.role === "USER") {
            window.location.pathname = "/certificate/details";
          }
        }
      }
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Something went wrong!",
        text: `Invalid OTP!`,
      });

      dispatch({ type: OTP_VERIFICATION, payload: err.response });
      // window.location.pathname = "/";
    });
};

export const getRegisterUser = (data) => (dispatch) => {
  const API_URL = `${BASE_URL}cportal/registeruser`;

  axios
    .post(API_URL, data)
    .then((res) => {
      dispatch({ type: REGISTER_USER, payload: res });

      if (res.status === 200) {
        Swal.fire({
          icon: "info",
          title: "Success!",
          text: "Your request has been submitted successfully and Credentials will be shared after approval.",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.pathname = "/";
          }
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: err.response.data.message ?? "Unable to Register.",
      });

      dispatch({ type: REGISTER_USER, payload: err.response });
    });
};

export const updateForgotPassword =
  (data, setForgotPasswordStatus, setOtpVerificationStatus) => (dispatch) => {
    const API_URL = `${BASE_URL}cportal/forgotpassword`;

    axios
      .post(API_URL, data)
      .then((res) => {
        dispatch({ type: FORGOT_PASSWORD, payload: res });
        if (res.status === 200) {
          setForgotPasswordStatus((prev) => !prev);
          setOtpVerificationStatus((prev) => !prev);
        }
        // if (res.status === 200) {
        //   Swal.fire({
        //     icon: "success",
        //     title: "Success!",
        //     text: "The user is active now!",
        //     confirmButtonText: "OK",
        //   }).then((result) => {
        //     if (result.isConfirmed) {
        //       window.location.pathname = "/admin/users";
        //     }
        //   });
        // }
      })
      .catch((err) => {
        dispatch({ type: FORGOT_PASSWORD, payload: err });
        Swal.fire({
          icon: "error",
          title: "Unable to Send OTP",
          // text: err.response.data,
          text: err.response.data.message,
        });
      });
  };

export const updateResendPassword =
  (data, setMinutes, setSeconds) => (dispatch) => {
    const API_URL = `${BASE_URL}cportal/user/resendotp`;

    axios
      .post(API_URL, data)
      .then((res) => {
        dispatch({ type: RESEND_PASSWORD, payload: res });
        setMinutes(2);
        setSeconds(59);
        // if (res.status === 200) {
        //   Swal.fire({
        //     icon: "success",
        //     title: "Success!",
        //     text: "The user is active now!",
        //     confirmButtonText: "OK",
        //   }).then((result) => {
        //     if (result.isConfirmed) {
        //       window.location.pathname = "/admin/users";
        //     }
        //   });
        // }
      })
      .catch((err) => {
        dispatch({ type: RESEND_PASSWORD, payload: err });
        Swal.fire({
          icon: "error",
          title: "Unable to Send OTP",
          // text: err.response.data,
          text: err.response.data.message,
        });
      });
  };

export const updateVerifyForgotPasswordOtp =
  (data, setResetPasswordStatus, setToken) => (dispatch) => {
    const API_URL = `${BASE_URL}cportal/verifyresetotp`;
    axios
      .post(API_URL, data)
      .then((res) => {
        dispatch({ type: VERIFY_FORGOT_PASSWORD_OTP, payload: res });

        if (res.status === 200) {
          setToken(res.data.data.token);
          setResetPasswordStatus((prev) => !prev);
          // Swal.fire({
          //   icon: "success",
          //   title: "Success!",
          //   text: "The user is active now!",
          //   confirmButtonText: "OK",
          // }).then((result) => {
          //   if (result.isConfirmed) {
          //     window.location.pathname = "/admin/users";
          //   }
          // });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: err.response.data.message,
          confirmButtonText: "OK",
        });
        dispatch({ type: VERIFY_FORGOT_PASSWORD_OTP, payload: err.response });
      });
  };

export const updateResetPassword =
  (data, setOtpVerificationStatus) => (dispatch) => {
    const API_URL = `${BASE_URL}cportal/resetpassword`;
    axios
      .post(API_URL, data)
      .then((res) => {
        dispatch({ type: RESET_PASSWORD, payload: res });

        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Your password is changed successfully!",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              setOtpVerificationStatus((prev) => !prev);
              // window.location.pathname = "/";
            }
          });
        }
      })
      .catch((err) => {
        dispatch({ type: RESET_PASSWORD, payload: err.response });
      });
  };

// ** after login ** //
export const concurrencyLoginData = () => {
  axios
    .get(BASE_URL + `cportal/user/refreshtoken`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("refreshToken"),
      },
    })
    .then((res) => {
      sessionStorage.setItem("token", res.data.token);
      sessionStorage.setItem("refreshToken", res.data.refreshToken);
      window.location.reload();
    })
    .catch((err) => {
      const data = {
        refreshToken: sessionStorage.getItem("refreshToken"),
      };

      axios
        .post(BASE_URL + "cportal/user/logout", data)
        .then((res) => {
          Swal.fire({
            icon: "error",
            title: "Session Expired!",
            // text: "The user is active now!",
          }).then(() => {
            window.location.pathname = "/";
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Session Expired!",
            // text: "The user is active now!",
          }).then(() => {
            window.location.pathname = "/";
          });
        });
    });
};

export const getAllUserAdmin = () => (dispatch) => {
  const API_URL = `${BASE_URL}cportal/dashboard/users`;

  axios
    .get(API_URL, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
    .then((res) => {
      //
      dispatch({ type: GET_ALL_USER_ADMIN, payload: res.data });
    })
    .catch((err) => {
      // dispatch({ type: GET_ALL_USER_ADMIN, payload: err.response });
      // ******concurrency login ****** //

      if (err.response.status === 401) {
        concurrencyLoginData();
      } else {
        dispatch({ type: GET_ALL_USER_ADMIN, payload: err.response });
      }

      // ******concurrency login ****** //
    });
};

export const updateBlockedUser = (data) => (dispatch) => {
  const API_URL = `${BASE_URL}cportal/dashboard/blockuser`;
  axios
    .post(API_URL, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
    .then((res) => {
      dispatch({ type: UPDATE_USER_BLOCKED, payload: res });

      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "The user is blocked now!",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.pathname = "/admin/users";
          }
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Error!",
        // text: err.response.data.message,
        text: "Something went wrong! Please try again!",
      });

      // dispatch({ type: UPDATE_USER_BLOCKED, payload: err.response });
      if (err.response.status == 401) {
        concurrencyLoginData();
      } else {
        dispatch({ type: UPDATE_USER_BLOCKED, payload: err.response });
      }
    });
};

export const updateApprovedUser = (data) => (dispatch) => {
  const API_URL = `${BASE_URL}cportal/dashboard/approveuser`;
  axios
    .post(API_URL, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
    .then((res) => {
      dispatch({ type: UPDATE_USER_APPROVED, payload: res });

      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "The user is active now!",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.pathname = "/admin/users";
          }
        });
      }
    })
    .catch((err) => {
      // dispatch({ type: UPDATE_USER_APPROVED, payload: err.response });
      if (err.response.status == 401) {
        concurrencyLoginData();
      } else {
        dispatch({ type: UPDATE_USER_APPROVED, payload: err.response });
      }
    });
};

export const getUserAdmin = (data) => (dispatch) => {
  const API_URL = `${BASE_URL}cportal/dashboard/get_form/${data}`;

  axios
    .get(API_URL, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
    .then((res) => {
      //
      dispatch({ type: GET_REQUEST_USER_FORM, payload: res.data });
    })
    .catch((err) => {
      // dispatch({ type: GET_ALL_USER_ADMIN, payload: err.response });
      // ******concurrency login ****** //

      if (err.response.status === 401) {
        concurrencyLoginData();
      } else {
        dispatch({ type: GET_REQUEST_USER_FORM, payload: err.response });
      }

      // ******concurrency login ****** //
    });
};

// logout session
export const logoutSession = () => (dispatch) => {
  const data = {
    refreshToken: sessionStorage.getItem("refreshToken"),
  };
  axios
    .post(BASE_URL + "cportal/user/logout", data)
    .then((res) => {
      // sessionStorage.removeItem("refreshToken");
      // sessionStorage.removeItem("token");
      sessionStorage.clear();
      localStorage.clear();
      Swal.fire({
        icon: "success",
        title: "Session Expired!",
      }).then(() => {
        window.location.pathname = "/";
      });
    })
    .catch((err) => {
      sessionStorage.removeItem("refreshToken");
      sessionStorage.removeItem("token");
      Swal.fire({
        icon: "error",
        title: "Session Expired!",
      }).then(() => {
        window.location.pathname = "/";
      });
    });
};
