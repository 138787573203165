import OTPInput from "otp-input-react";
import { useEffect, useState } from "react";
import { CgLogIn } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import {
  getOtpStatus,
  updateForgotPassword,
  updateResendPassword,
  updateResetPassword,
  updateVerifyForgotPasswordOtp,
} from "store/action";
import * as CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Swal from "sweetalert2";

const OtpVerify = (props) => {
  // const navigate = useNavigate();
  //
  const dispatch = useDispatch();
  const [otpText, setOtpText] = useState("");
  const [resetPasswordStatus, setResetPasswordStatus] = useState(false); //
  const [isPasswordVisiable, setIsPassowrdVisiable] = useState(false);
  const [isConfirmPasswordVisiable, setIsConfirmPassowrdVisiable] =
    useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [token, setToken] = useState("");

  // timer
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(59);

  const forgotPasswordData = useSelector(
    (state) => state.Admin.forgotPasswordData
  );

  useEffect(() => {
    if (forgotPasswordData.status === 200) {
      setMinutes(2);
      setSeconds(59);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: forgotPasswordData.data.message,
        // confirmButtonText: "OK",
      });
    }
  }, [forgotPasswordData]);

  useEffect(() => {
    const otpTimer = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prev) => prev - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(otpTimer);
          Swal.fire({
            icon: "warning",
            title: "Timeout!",
            text: "Session Timeout!",
            confirmButtonText: "Go Back",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.pathname = "/";
            }
          });
        } else {
          setMinutes((prev) => prev - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(otpTimer);
  });

  const encapsulateData = (encapData) => {
    const encryptconfigs = {
      key: "t700#zkrF@db0705",
      iv: "i700#zkrF@db0705",
    };

    var key = CryptoJS.enc.Latin1.parse(encryptconfigs.key);
    var iv = CryptoJS.enc.Latin1.parse(encryptconfigs.iv);
    var encryptedData = CryptoJS.AES.encrypt(encapData, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    encryptedData = encryptedData.toString();
    //
    return encryptedData;
  };

  const onClickSubmitOtp = () => {
    const body = {
      email: encapsulateData(props.username),
      otp: encapsulateData(otpText),
    };
    if (props.forgotPasswordStatus === false) {
      dispatch(getOtpStatus(body, props.setOtpVerificationStatus));
    }

    if (props.forgotPasswordStatus === true) {
      dispatch(
        updateVerifyForgotPasswordOtp(body, setResetPasswordStatus, setToken)
      );
    }
  };

  const onChangeOtp = (event) => {
    setOtpText(event);
  };

  const onChangePassword = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "newPassword") {
      setNewPassword(value);
    }

    if (name === "newConfirmPassword") {
      setConfirmNewPassword(value);
    }
  };

  const onClickBack = () => {
    props.setForgotPasswordStatus((prev) => !prev);
    props.setOtpVerificationStatus((prev) => !prev);
  };

  const onClickSubmitNewPassword = () => {
    if (newPassword === "" || confirmNewPassword === "") {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Please enter your password and confirm password details.",
      });
    } else {
      if (newPassword === confirmNewPassword) {
        const body = {
          email: encapsulateData(props.username),
          password: encapsulateData(newPassword),
          confirmpassword: encapsulateData(confirmNewPassword),
          token: token,
        };

        dispatch(updateResetPassword(body, props.setOtpVerificationStatus));
        setResetPasswordStatus((prev) => !prev);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Your new password and confirmation password do not match.",
        });
      }
    }
  };

  function resendOtp() {
    if (props.username === "" || !props.username.includes("@")) {
      // Swal.fire({
      //   icon: "error",
      //   title: "Unable to Send OTP",
      //   text: "Invalid Email address",
      // });
      return;
    }

    const body = { email: encapsulateData(props.username) };

    if (props.forgotPasswordStatus === true) {
      dispatch(updateForgotPassword(body));
    } else {
      dispatch(updateResendPassword(body, setMinutes, setSeconds));
    }
  }

  return (
    <div
      className="col-md-5"
      style={{
        marginTop: "26px",
        marginLeft: "-108px",
        width: "400px",
        height: "fit-content",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "11px",
        opacity: "1",
        backgroundColor: "#FFFFFF",
        padding: "20px",
      }}
    >
      <div className="login-card">
        <div className="row">
          <h5 className="d-flex align-items-center gap-3">
            <span>
              <CgLogIn
                style={{
                  fontSize: "26px",
                  color: "#902A2B",
                  fontWeight: "700",
                }}
              />
            </span>
            Sign In
          </h5>
        </div>
        {resetPasswordStatus && (
          <div>
            <div
              className="d-flex flex-column gap-1"
              style={{ margin: "10px" }}
            >
              <div className="d-flex flex-column text-left">
                <p
                  className="m-1"
                  style={{
                    textAlign: "left",
                    color: "#292828D8",
                    fontWeight: "500",
                  }}
                >
                  New Password
                </p>
                <span
                  className="d-flex align-items-center m-1"
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    backgroundColor: "#f7fafc",
                  }}
                >
                  <input
                    required
                    type={isPasswordVisiable ? "text" : "password"}
                    name="newPassword"
                    onChange={onChangePassword}
                    className="p-2 d-flex flex-grow-1"
                    placeholder="New password"
                    style={{
                      backgroundColor: "#F7FAFC",
                      borderRadius: "11px",
                      border: "none",
                      // width: "90%",
                    }}
                  />
                  {isPasswordVisiable ? (
                    <AiFillEyeInvisible
                      onClick={() => setIsPassowrdVisiable((prev) => !prev)}
                      style={{ fontSize: "20px", marginRight: "4px" }}
                    />
                  ) : (
                    <AiFillEye
                      onClick={() => setIsPassowrdVisiable((prev) => !prev)}
                      style={{ fontSize: "20px", marginRight: "4px" }}
                    />
                  )}
                </span>
              </div>

              <div className="d-flex flex-column text-left">
                <p
                  className="m-1"
                  style={{
                    textAlign: "left",
                    color: "#292828D8",
                    fontWeight: "500",
                  }}
                >
                  Confirm Password
                </p>
                <span
                  className="d-flex align-items-center m-1"
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    backgroundColor: "#f7fafc",
                  }}
                >
                  <input
                    required
                    type={isConfirmPasswordVisiable ? "text" : "password"}
                    name="newConfirmPassword"
                    onChange={onChangePassword}
                    className="p-2 d-flex flex-grow-1"
                    placeholder="Confirm password"
                    style={{
                      backgroundColor: "#F7FAFC",
                      borderRadius: "11px",
                      border: "none",
                      // width: "90%",
                    }}
                  />
                  {isPasswordVisiable ? (
                    <AiFillEyeInvisible
                      onClick={() =>
                        setIsConfirmPassowrdVisiable((prev) => !prev)
                      }
                      style={{ fontSize: "20px", marginRight: "4px" }}
                    />
                  ) : (
                    <AiFillEye
                      onClick={() =>
                        setIsConfirmPassowrdVisiable((prev) => !prev)
                      }
                      style={{ fontSize: "20px", marginRight: "4px" }}
                    />
                  )}
                </span>
              </div>

              <div className="d-flex justify-content-end align-items-center mt-3">
                <button
                  className="px-3 py-2"
                  style={{
                    backgroundColor: "#902A2B",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#FFFFFF",
                    border: "none",
                    borderRadius: "25px",
                  }}
                  onClick={onClickSubmitNewPassword}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
        {!resetPasswordStatus && (
          <div>
            <div className="row">
              <div className="col-md-12">
                <label
                  style={{
                    color: "#292828D8",
                    fontSize: "17px",
                    marginTop: "40px",
                    fontWeight: "600",
                  }}
                >
                  Enter OTP from your Registered Email Id?
                </label>
              </div>
            </div>
            <div className="row" style={{ marginTop: "30px" }}>
              <div id="OtpBox">
                <OTPInput
                  style={{ justifyContent: "center", marginLeft: "20px" }}
                  value={otpText}
                  onChange={onChangeOtp}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  secure
                />
              </div>
            </div>
            <div id="rsndOtp" style={{ marginTop: "10px" }}>
              <a
                style={{
                  color: "#292828D8",
                  fontSize: "17px",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                Didn’t Receive Code?{" "}
                <span
                  // onClick={props.resendOtp}
                  onClick={resendOtp}
                  style={{ color: "#377D22" }}
                >
                  {" "}
                  Resend OTP
                </span>
              </a>
            </div>
            <div className="col-md-12">
              <label
                style={{
                  color:
                    seconds < 60 && minutes === 0 ? "#902A2B" : "#292828D8",
                  fontSize: "17px",
                  fontWeight: "600",
                  marginTop: "10px",
                }}
              >
                Remaining Time: {minutes} :{" "}
                {seconds < 10 ? `0${seconds}` : seconds}
              </label>
            </div>
            <div className="row mt-5 mb-3 ">
              <div className="col-md-12 d-flex justify-content-between">
                <button
                  onClick={onClickBack}
                  className="px-3 py-2"
                  style={{
                    backgroundColor: "#902A2B",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#FFFFFF",
                    border: "none",
                    borderRadius: "25px",
                    marginRight: "20px",
                  }}
                >
                  Go Back
                </button>
                {/* <button
                  onClick={onClickBack}
                  className="login-submit"
                  style={{
                    color: "white",
                    backgroundColor: "#20570F",
                    border: "none",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    fontWeight: "bold",
                    marginRight: "20px",
                  }}
                >
                  Go Back
                </button> */}
                <button
                  onClick={onClickSubmitOtp}
                  className="px-3 py-2"
                  style={{
                    backgroundColor: "#902A2B",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#FFFFFF",
                    border: "none",
                    borderRadius: "25px",
                  }}
                >
                  Submit
                </button>
                {/* <button
                  onClick={onClickSubmitOtp}
                  className="login-submit"
                  type="submit"
                  style={{
                    color: "white",
                    backgroundColor: "#20570F",
                    border: "none",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    fontWeight: "bold",
                  }}
                >
                  Log In
                </button> */}

                {/* {minutes === 0 && seconds === 0 ? (
                        <h1
                          style={{
                            fontSize: "17px",
                            marginLeft: "10px",
                            marginTop: "10px",
                          }}
                        >
                          Times Up !
                        </h1>
                      ) : (
                        <h1
                          style={{
                            fontSize: "17px",
                            marginLeft: "10px",
                            marginTop: "10px",
                          }}
                        >
                          Time Remaining: {minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </h1>
                      )} */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OtpVerify;
