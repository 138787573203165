import React from "react";
import logo from "../../assets/Mask Group 1.png";
import "../../style.css";
import Modal from "react-modal";
import imgsoon from "../../assets/undraw_certification_re_ifll.svg";
import user from "../../assets/Group 1.svg";
import axios from "axios";
import { BASE_URL } from "utilities/config";
import Swal from "sweetalert2";

// class MainHeader extends React.Component {

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 3px 20px #377D2221",
  },
};
const MainHeader = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const logoutSession = () => {
    const data = {
      refreshToken: sessionStorage.getItem("refreshToken"),
    };
    /* AXIOS INTERCEPTOR */
    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        if (err.response.status === 502) {
          window.location.href = "/service-unavailable";
        }
        return Promise.reject(err);
      }
    );
    axios
      .post(BASE_URL + "cportal/user/logout", data)
      .then((res) => {
        sessionStorage.removeItem("refreshToken");
        sessionStorage.removeItem("token");
        sessionStorage.clear();
        Swal.fire({
          icon: "success",
          title: "Session Expired!",
        }).then(() => {
          window.location.pathname = "/";
        });
      })
      .catch((err) => {
        sessionStorage.removeItem("refreshToken");
        sessionStorage.removeItem("token");
        Swal.fire({
          icon: "error",
          title: "Session Expired!",
        }).then(() => {
          window.location.pathname = "/";
        });
      });
  };
  return (
    <div
      className="d-flex justify-content-between"
      style={{ backgroundColor: "#902A2B", height: "60px", width: "98vw"}}
    >
      <div className="" style={{ width: "50%" }}>
        <div className="col-12">
          <div style={{ display: "flex" }}>
            <img
              src={logo}
              style={{
                width: "45px",
                height: "45px",
                marginTop: "8px",
                marginLeft: "5%",
              }}
            />
            <p
              id="enter"
              style={{
                color: "#FFFFFF",
                marginTop: "15px",
                marginLeft: "20px",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              Certificate Portal
            </p>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="row" style={{ backgroundColor: "#902A2B" }}>
          <div className="col-md-6">
            <div className="" style={{ display: "flex" }}>
              <img
                src={logo}
                style={{
                  width: "50px",
                  marginTop: "3px",
                  marginBottom: "3px",
                  marginLeft: "5%",
                }}
              />
              <p
                id="enter"
                style={{
                  color: "#FFFFFF",
                  marginTop: "20px",
                  marginLeft: "20px",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                Certificate Portal
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <a
              style={{
                fontWeight: "bold",
                marginTop: "20px",
                fontSize: "30px",
                cursor: "pointer",
                textAlign: "right",
                color: "white",
                marginLeft: "95%",
              }}
              onClick={() => setIsOpen(false)}
            >
              X
            </a>
          </div>
        </div>
        <div>
          <div style={{ marginLeft: "40%", marginTop: "10%" }}>
            <img src={imgsoon} width={200} />
          </div>
          <div>
            <p
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                color: "#292828D8",
                marginLeft: "15%",
              }}
              id="enter"
            >
              The Certificate Verification Portal Will Be In Live Soon!
            </p>
          </div>
          <div>
            <button
              onClick={closeModal}
              style={{
                marginLeft: "46%",
                padding: "10px 20px",
                backgroundColor: "#902A2B",
                borderColor: "#902A2B",
                borderRadius: "8px",
                color: "#FFFFFF",
              }}
            >
              {" "}
              Okay
            </button>
          </div>
        </div>
      </Modal>
      <div className="d-flex align-items-center mx-4">
        {window.location.pathname === "/certificate/details" && (
          <div className="d-flex align-items-center">
            <img src={user} style={{ width: "30px", marginLeft: "10px" }} />

            <div className="dropdown mx-3">
              <div
                className=" text-white dropdown-toggle"
                type="button"
                id="dropdownMenu2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ fontSize: "24px" }}
              ></div>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                <li>
                  <button
                    onClick={() => {
                      logoutSession();
                    }}
                    className="dropdown-item"
                    type="button"
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainHeader;
