import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { ReactComponent as Verify } from "assets/Verify.svg";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Buffer } from "buffer";
import "../../../style.css";
import logo from "../../../assets/Mask Group 1.png";
import { BASE_URL } from "utilities/config";

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    display: "flex",
    fontSize: "26px",
    fontWeight: 500,
    alignItems: "center",
  },
  verifyContainer: {
    padding: "40px",
    width: "100%",
    backgroundColor: "#00000038",
    height: "107%",
  },
  submitBtn: {
    width: "fit-content",
    marginTop: "5%",
    fontWeight: "bold",
    marginLeft: "80%",
    "& button": {
      borderRadius: "10px",
      padding: "10px 25px",
      backgroundColor: "#902a2b",
    },
  },
  fieldLayout: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: "50px",
  },
  fieldContainer: {
    width: "40%",
    marginBottom: "30px",
  },
  inputbox: {
    width: "80%",
    height: "41px",
    padding: "12px 20px",
    boxSizing: "border-box",
    border: "2px solid #ccc",
    borderRadius: "8px",
    // backgroundColor: '#ccc',
    resize: "none",
  },
  label: {
    textAlign: "left",
    fontWeight: "bold",
    padding: "10px 15px",
  },
}));

const VerifyCertificate = (props) => {
  const params = useParams();

  const data = params.id.split();

  const [searchParams] = useSearchParams();

  var Type = searchParams.get("type");
  var userName = searchParams.get("name");
  var userMail = searchParams.get("mail");

  let buff = Buffer.from(userName, "base64");
  userName = buff.toString("ascii");

  let buffi = Buffer.from(userMail, "base64");
  userMail = buffi.toString("ascii");

  // let bufftype = Buffer.from(Type, "base64");
  // Type = bufftype.toString("ascii");
  //

  const classes = useStyles();
  const navigate = useNavigate();
  const [name, setName] = useState(userName);
  const [institute, setInstitute] = useState(undefined);
  const [designation, setDesignation] = useState(undefined);
  const [email, setEmail] = useState(userMail);
  const [mobile, setMobile] = useState(undefined);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      goToUpload();
    }
  };

  const goToUpload = async () => {
    const body = {
      name: name,
      email: email,
      mobileno: mobile !== undefined ? mobile : "NA",
      nameofinstitute: institute !== undefined ? institute : "NA",
      designation: designation !== undefined ? designation : "NA",
      linktype: Type,
      shareid: data[0],
    };

    // await localStorage.setItem(
    //   "certificate_details",
    //   JSON.stringify({
    //     id: data[0],
    //     name: name,
    //     mail: email,
    //     type: Type,
    //   })
    // );
    // navigate("/");

    // axios
    //   .post(`${BASE_URL}cportal/public/recinfo`, body)
    /* AXIOS INTERCEPTOR */
    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        if (err.response.status === 502) {
          window.location.href = "/service-unavailable";
        }
        return Promise.reject(err);
      }
    );

    axios
      .post(`${BASE_URL}cportal/public/recinfo`, body)
      .then((response) => {
        if (response.data.message === "Data saved Successfully!!") {
          localStorage.setItem(
            "certificate_details",
            JSON.stringify({
              id: data[0],
              name: name,
              mail: email,
              type: Type,
            })
          );
          navigate("/");
        }
      })
      .catch((err) => {
        if (err.response.data.message === " Link is expired") {
          const name = params.id;
          navigate(`/wallet/escert/verifylink/${name}`);
        }
      });
  };

  return (
    <div className={classes.verifyContainer}>
      <div id="popStyle" className="pb-4">
        <div
          style={{ width: "100%", height: "61px", backgroundColor: "#902A2B" }}
        >
          <div className="d-flex justify-content-between align-items-center px-2">
            <div className="d-flex">
              <img
                src={logo}
                style={{ width: "50px", height: "50px", marginTop: "6px" }}
              />
              <p
                id="enter"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "white",
                  marginTop: "18px",
                }}
              >
                Certificate Portal
              </p>
            </div>
            <a href="/" id="cross">
              X
            </a>
          </div>
        </div>
        <div
          className={classes.header}
          style={{ marginLeft: "20px", marginTop: "20px" }}
        >
          <Verify style={{ height: "40px" }} />
          <div id="enter" style={{ fontSize: "18px", fontWeight: "bold" }}>
            Enter details to verify certificate.
          </div>
        </div>

        <div>
          <div
            className="row"
            style={{ marginLeft: "9%", marginTop: "1%", width: "100%" }}
          >
            <div className="col-md-5">
              <div className="row">
                <label id="enter" className={classes.label}>
                  Enter Your Name
                </label>
                <br />
                <input
                  className={classes.inputbox}
                  disabled
                  style={{ backgroundColor: "#E2E2E2" }}
                  type="text"
                  value={name}
                  placeholder="Enter a name"
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </div>
              <div className="row">
                <label id="enter" className={classes.label}>
                  Institution / Organization Name{" "}
                  <span style={{ fontWeight: "normal", color: "#292828D8" }}>
                    {" "}
                    &nbsp;(Optional)
                  </span>
                </label>
                <br />
                <input
                  type="text"
                  className={classes.inputbox}
                  style={{ backgroundColor: "#EAEDED" }}
                  value={institute}
                  placeholder="Enter a institute"
                  onChange={(e) => setInstitute(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="row">
                <label id="enter" className={classes.label}>
                  Designation{" "}
                  <span style={{ fontWeight: "normal", color: "#292828D8" }}>
                    {" "}
                    &nbsp;(Optional)
                  </span>
                </label>
                <br />
                <input
                  type="text"
                  className={classes.inputbox}
                  style={{ backgroundColor: "#EAEDED" }}
                  value={designation}
                  placeholder="Enter a designation"
                  onChange={(e) => setDesignation(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
            <div className="col-md-5 offset-md-1">
              <div className="row">
                <label id="enter" className={classes.label}>
                  Email Id
                </label>
                <br />
                <input
                  type="text"
                  className={classes.inputbox}
                  disabled
                  style={{ backgroundColor: "#E2E2E2" }}
                  value={email}
                  placeholder="Enter a email id"
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="row">
                <label id="enter" className={classes.label}>
                  Mobile Number{" "}
                  <span style={{ fontWeight: "normal", color: "#292828D8" }}>
                    {" "}
                    &nbsp;(Optional)
                  </span>
                </label>
                <br />
                <input
                  type="text"
                  className={classes.inputbox}
                  style={{ backgroundColor: "#EAEDED" }}
                  value={mobile}
                  placeholder="Enter a mobile no"
                  onChange={(e) => setMobile(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={classes.submitBtn}>
          <Button
            onClick={goToUpload}
            id="enter"
            style={{ fontSize: "17px", fontWeight: "bold" }}
            variant="contained"
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VerifyCertificate;
