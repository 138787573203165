import React from "react";
import { ArrowBack } from "@mui/icons-material";
import "./header.css";

class ContentHeader extends React.Component {
  render() {
    const { showBackIcon, HeaderIcon, title, handleBackButton } = this.props;
    return (
      <div className="container">
        {showBackIcon && (
          <ArrowBack
            className="header-icon pointer"
            onClick={handleBackButton}
          />
        )}
        <HeaderIcon className="header-icon" />
        <span style={{ color: "#292828d9" }} className="content-title">
          {title}
        </span>
      </div>
    );
  }
}

export default ContentHeader;
