export const POST_LOGIN_ERR = "POST_LOGIN_ERR";
export const POST_LOGIN = "POST_LOGIN"
export const VERIFY_OTP_ERR = "VERIFY_OTP_ERR"
export const VERIFY_OTP =  "VERIFY_OTP"
export const GET_ALL_USERS_ADMIN = "GET_ALL_USERS_ADMIN"
export const GET_COUNT_ADMIN = "GET_COUNT_ADMIN"
export const GET_GRAPH_DATA = "GET_GRAPH_DATA"
export const GET_FIRST_GRAPH_DATA = "GET_FIRST_GRAPH_DATA"
export const GET_COUNT_DATA = "GET_COUNT_DATA"
export const GET_COUNT_VERIFIED = "GET_COUNT_VERIFIED"
export const GET_STACK_DATA = "GET_STACK_DATA"
export const NOT_REDIRECT = "NOT_REDIRECT"
export const GET_REVOKE_USERS = "GET_REVOKE_USERS"
export const BLOCK_USER = "BLOCK_USER"
export const GET_TOTAL_USER_COUNT = "GET_TOTAL_USER_COUNT"
export const GET_ALL_BLOCKED_USERS = "GET_ALL_BLOCKED_USERS"
export const BLOCK_USER_ERR = "BLOCK_USER_ERR"
export const GET_ALL_USERS = "GET_ALL_USERS"