import React from "react";
import logo from "../../assets/Mask Group 1.png";
import user from "../../assets/Group 1.svg";
import axios from "axios";
import { BASE_URL } from "utilities/config";
import Swal from "sweetalert2";

class AdminHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    //
  }

  logoutSession = () => {
    const data = {
      refreshToken: sessionStorage.getItem("refreshToken"),
    };
    /* AXIOS INTERCEPTOR */
    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        if (err.response.status === 502) {
          window.location.href = "/service-unavailable";
        }
        return Promise.reject(err);
      }
    );
    axios
      .post(BASE_URL + "cportal/user/logout", data)
      .then((res) => {
        sessionStorage.removeItem("refreshToken");
        sessionStorage.removeItem("token");
        sessionStorage.clear();
        Swal.fire({
          icon: "success",
          title: "Session Expired!",
        }).then(() => {
          window.location.pathname = "/";
        });
      })
      .catch((err) => {
        sessionStorage.removeItem("refreshToken");
        sessionStorage.removeItem("token");
        Swal.fire({
          icon: "error",
          title: "Session Expired!",
        }).then(() => {
          window.location.pathname = "/";
        });
      });
  };

  render() {
    return (
      <div>
        <div
          //   className="container-fluid"
          className="d-flex align-items-center"
          style={{
            height: "70px",
            width: "100%",
            backgroundColor: "#902A2B",
            padding: "0 40px",
          }}
        >
          <div className="d-flex align-items-center gap-2 w-100 h-100">
            <img src={logo} style={{ width: "50px", height: "50px" }} />
            <span
              style={{
                color: "white",
                // marginTop: "23px",
                fontSize: "18px",
                fontWeight: "400",
              }}
            >
              Admin For Citizen wallet
            </span>
            <div style={{ marginLeft: "auto" }}>
              <ul
                className="d-flex align-items-center m-0 "
                style={{
                  listStyle: "none",
                  display: "flex",
                  //   marginTop: "23px",
                }}
              >
                <li>
                  <a
                    style={{
                      color: "white",
                      marginRight: "20px",
                      //   marginTop: "23px",
                      fontSize: "18px",
                      fontWeight: "400",
                      padding: "5px 20px",
                      paddingBottom: "20px",
                      borderBottom:
                        window.location.pathname === "/admin/users"
                          ? "5px solid #ffffff"
                          : "none",
                    }}
                  >
                    Users
                  </a>
                </li>
                {/* <li>
                  <a
                    style={{
                      color: "white",
                      marginTop: "23px",
                      fontSize: "15px",
                      fontWeight: "bold",
                      padding: "5px 30px",
                    }}
                  >
                    MIS Report
                  </a>
                </li>
                <li>
                  <a
                    style={{
                      color: "white",
                      marginTop: "23px",
                      fontSize: "15px",
                      fontWeight: "bold",
                      padding: "5px 30px",
                    }}
                  >
                    Add User
                  </a>
                </li> */}
                <li>
                  <a
                    className="d-flex align-items-center"
                    style={{
                      color: "white",
                      //   marginTop: "23px",
                      fontSize: "18px",
                      fontWeight: "400",
                      padding: "5px 30px",
                      textDecoration: "none",
                    }}
                  >
                    Admin
                    <img
                      src={user}
                      style={{ width: "30px", marginLeft: "10px" }}
                    />
                  </a>
                </li>
                {/* <li>
                  <select
                    style={{
                      backgroundColor: "#902A2B",
                      color: "#FFFFFF",
                      border: "none",
                    }}
                  >
                    <option
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "5px 30px",
                      }}
                    ></option>
                    <option
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "5px 30px",
                      }}
                    >
                      Logout
                    </option>
                    <option
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "5px 30px",
                      }}
                    ></option>
                  </select>
                </li> */}
                <div className="dropdown">
                  <div
                    className=" text-white dropdown-toggle"
                    type="button"
                    id="dropdownMenu2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ fontSize: "24px" }}
                  ></div>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                    <li>
                      <button
                        onClick={() => {
                          this.logoutSession();
                        }}
                        className="dropdown-item"
                        type="button"
                      >
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdminHeader;
