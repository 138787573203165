import React from "react";
import { makeStyles } from "@mui/styles";
import AppGrid from "components/Table/grid.jsx";
import { ReactComponent as DocCheck } from "assets/Recycle.svg";
import ContentHeader from "components/ContentHeader";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  gridContent: {
    flex: 1,
    margin: "32px",
  },
}));

const TableColumns = [
  { headerName: "Document ID", field: "docId", flex: 1, sortable: false },
  { headerName: "Year", field: "year", flex: 1, sortable: false },
  {
    headerName: "Transaction ID",
    field: "transactionId",
    flex: 1,
    sortable: false,
  },
  {
    headerName: "Status",
    field: "status",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <div className="status-cell">
          {" "}
          {params.value === "Added to BlockChain" ? (
            <span className="status-active"></span>
          ) : (
            ""
          )}{" "}
          {params.value}
        </div>
      );
    },
  },
];

const TableData = [
  {
    id: 1,
    docId: 1,
    year: "2001",
    transactionId: "12",
    status: "Added to BlockChain",
  },
  {
    id: 2,
    docId: 2,
    year: "2002",
    transactionId: "123",
    status: "Added to BlockChain",
  },
  {
    id: 3,
    docId: 3,
    year: "2003",
    transactionId: "23",
    status: "Added to BlockChain",
  },
  {
    id: 4,
    docId: 4,
    year: "2004",
    transactionId: "13",
    status: "Added to BlockChain",
  },
  {
    id: 5,
    docId: 5,
    year: "2005",
    transactionId: "22",
    status: "Added to BlockChain",
  },
  {
    id: 6,
    docId: 6,
    year: "2006",
    transactionId: "44",
    status: "Added to BlockChain",
  },
  {
    id: 7,
    docId: 1,
    year: "2001",
    transactionId: "12",
    status: "Added to BlockChain",
  },
  {
    id: 8,
    docId: 2,
    year: "2002",
    transactionId: "123",
    status: "Added to BlockChain",
  },
  {
    id: 9,
    docId: 3,
    year: "2003",
    transactionId: "23",
    status: "Added to BlockChain",
  },
  {
    id: 10,
    docId: 4,
    year: "2004",
    transactionId: "13",
    status: "Added to BlockChain",
  },
  {
    id: 11,
    docId: 5,
    year: "2005",
    transactionId: "22",
    status: "Added to BlockChain",
  },
  {
    id: 12,
    docId: 6,
    year: "2006",
    transactionId: "44",
    status: "Added to BlockChain",
  },
];
const BulkUploadList = () => {
  const classes = useStyles();

  let navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className={classes.container}>
      <ContentHeader
        showBackIcon
        HeaderIcon={DocCheck}
        title="Bulk Upload"
        handleBackButton={goBack}
      />
      <div className={classes.gridContent}>
        <AppGrid columns={TableColumns} rows={TableData} />
      </div>
    </div>
  );
};

export default BulkUploadList;
