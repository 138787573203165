import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import { withStyles } from "@mui/styles";
import {
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { clsx } from "util.js";

export const FIELD_TYPES = {
  textInput: "text_input",
  select: "single_select",
  checkbox: "checkbox",
  radio: "radio",
};

const styles = (theme) => ({
  formControl: {
    width: "100%",
    borderRadius: "4px",
    background: "rgba(0, 0, 0, 0.06)",
    "& .MuiInputBase-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiSelect-outlined": {
      padding: "8px 0",
    },
  },
  root: {
    width: "100%",
    "& MuiFilledInput-root:before": {
      border: "none !important",
    },
  },
  selectRoot: {
    // '& MuiInputBase-root': {
    // 	width: '100%'
    // }
  },
  label: {
    color: "#292828d9",
    textAlign: "left",
    fontWeight: "bold",
    marginBottom: "6px",
  },
  input: {
    fontFamily: "sans-serif !important",
    "&:before": {
      border: "none !important",
    },
    "&::placeholder": {
      color: "blue",
    },
  },
});

class FieldRenderer extends Component {
  getComponent() {
    const {
      field,
      classes,
      label,
      dropdownOptions,
      handleChange,
      placeholder,
    } = this.props;
    const { checkboxLabel, checked, radioOptions = [] } = field;

    switch (field.type) {
      case FIELD_TYPES.textInput:
        return (
          <TextField
            hiddenLabel
            size="small"
            variant="filled"
            classes={{ root: classes.root, input: classes.input }}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes["input"] },
            }}
            placeholder={placeholder || label}
            value={field.value || ""}
          />
        );
      case FIELD_TYPES.select:
        return (
          <Select
            value={field.value}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            classes={{ root: classes.selectRoot }}
          >
            {dropdownOptions &&
              dropdownOptions.map((option) => (
                <MenuItem value={option.value}>{option.displayName}</MenuItem>
              ))}
          </Select>
        );
      case FIELD_TYPES.checkbox:
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={field.checked}
                onChange={handleChange}
                name={field.id}
              />
            }
            label={checkboxLabel}
          />
        );

      case FIELD_TYPES.radio:
        return (
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            row
          >
            {radioOptions.map((item) => (
              <FormControlLabel
                value={item.value}
                control={<Radio />}
                label={item.label}
              />
            ))}
          </RadioGroup>
        );
      default:
        return (
          <TextField
            hiddenLabel
            size="small"
            variant="filled"
            InputProps={{ disableUnderline: true }}
          />
        );
    }
  }

  render() {
    const { label, classes, propClasses } = this.props;
    return (
      <div className={classes.container}>
        {label && <div className={classes.label}>{label}</div>}
        <div className={classes.field}>
          <FormControl
            className={clsx([classes.formControl, classes.formControlProps])}
          >
            {this.getComponent()}
          </FormControl>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(FieldRenderer);
