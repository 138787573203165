import {
  GET_ALL_USER_ERROR,
  GET_ALL_USER,
  UPDATE_USER_DATA,
  UPDATE_USER_DATA_ERR,
  LOGIN,
  OTP_VERIFICATION,
  REGISTER_USER,
  GET_ALL_USER_ADMIN,
  UPDATE_USER_BLOCKED,
  UPDATE_USER_APPROVED,
  FORGOT_PASSWORD,
  VERIFY_FORGOT_PASSWORD_OTP,
  RESET_PASSWORD,
  LOGOUT_SESSION,
  GET_REQUEST_USER_FORM,
  RESEND_PASSWORD,
} from "./types";
const initialState = {
  getAllUser_response: "",
  getAllUser_response_err: "",
  updateUser_response: "",
  updateUser_response_err: "",
  getLoginData: [],
  getOtpVerificationData: [],
  getResgisterUserData: [],
  getAllUserAdmin: [],
  updateUserBlocked: [],
  updateUserApproved: [],
  forgotPasswordData: [],
  verifyForgotPasswordData: [],
  resetPasswordData: [],
  userRequestFormData: [],
  logoutSessionData: [],
  resendPassword: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_ALL_USER:
      return {
        ...state,
        getAllUser_response: payload,
      };
    case GET_ALL_USER_ERROR:
      return {
        ...state,
        getAllUser_response_err: payload,
      };
    case UPDATE_USER_DATA:
      return {
        ...state,
        updateUser_response: payload,
      };
    case UPDATE_USER_DATA_ERR:
      return {
        ...state,
        updateUser_response_err: payload,
      };
    case LOGIN:
      return {
        ...state,
        getLoginData: payload,
      };

    case OTP_VERIFICATION:
      return {
        ...state,
        getOtpVerificationData: payload,
      };

    case REGISTER_USER:
      return {
        ...state,
        getResgisterUserData: payload,
      };

    case GET_ALL_USER_ADMIN:
      return {
        ...state,
        getAllUserAdmin: payload,
      };

    case UPDATE_USER_BLOCKED:
      return {
        ...state,
        updateUserBlocked: payload,
      };
    case UPDATE_USER_APPROVED:
      return {
        ...state,
        updateUserApproved: payload,
      };

    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordData: payload,
      };

    case VERIFY_FORGOT_PASSWORD_OTP:
      return {
        ...state,
        verifyForgotPasswordData: payload,
      };

    case RESET_PASSWORD:
      return {
        ...state,
        resetPasswordData: payload,
      };

    case LOGOUT_SESSION:
      return {
        ...state,
        logoutSessionData: payload,
      };

    case GET_REQUEST_USER_FORM:
      return {
        ...state,
        userRequestFormData: payload,
      };
    case RESEND_PASSWORD:
      return {
        ...state,
        resendPassword: payload,
      };

    default:
      return {
        ...state,
      };
  }
}
