import React, { useEffect } from "react";
import "./App.css";
import HomePage from "./pages/HomePage";
import BulkUpload from "./pages/BulkUpload";
import BulkUploadList from "./pages/BulkUpload/UploadList.jsx";
import BatchList from "./pages/BatchList";
import VerifyCertificate from "./pages/Certificate/verify";
import LinkExpired from "./pages/Certificate/verify/LinkExpired";
import PaymentSummary from "./pages/Payment/Summary";
import AddTemplate from "./pages/Certificate/createTemplate";
import { ThemeProvider } from "@mui/system";
import theme from "./theme";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import RequestAccess from "./pages/RequestAccess";
import ModifyCertificate from "./pages/Certificate/modify";
import UploadCertificate from "./pages/Certificate/upload";
import CertificateDetails from "./pages/Certificate/details";
import WelcomePage from "./pages/Welcome";
import MainHeader from "./components/MainHeader";
import Admin from "./pages/Admin/Admin.jsx";

import CreateAccount from "./pages/CreateAccount";
// import AdminHeader from "./components/MainHeader/AdminHeader";
import Users from "./pages/AdminForCitizenWalltet/Users";
import UserRequestForm from "./pages/AdminForCitizenWalltet/UserRequestForm";
import axios from "axios";
import { BASE_URL } from "./utilities/config";
import ServiceUnavailable from "./pages/Auth/ServiceUnavailable";
import AdminPrivateRoute from "./PrivateRoutes/AdminPrivateRoute";
import UserPrivateRoute from "./PrivateRoutes/UserPrivateRoute";


//wallet Admin section
import FullLayoutComponent from "./pages/WalletAdminPages/Layout/FullLayoutComponent"
import LoginAdmin from "./pages/WalletAdminPages/Pages/LoginAdmin"
import ResetPasswordScreen from "./pages/WalletAdminPages/Pages/ResetPasswordScreen"
import "./style/WalletAdmin/style.css"
import "./style/WalletAdmin/App.css"
import AdminLayout from "./pages/WalletAdminPages/Layout/AdminLayout"
import AdminDashboard from "./pages/WalletAdminPages/Pages/AdminDashboard"
import UserManagement from "./pages/WalletAdminPages/Pages/UserManagement"
import AllDepartment from "./pages/WalletAdminPages/Pages/AllDepartment"
import ForgotpasswordScreen from "./pages/WalletAdminPages/Pages/ForgotpasswordScreen"

function App() {
  useEffect(() => {
    const path = window.location.pathname.split("/");
    console.log(window.location.pathname)
    console.log(path)
    if (path[1] === "link") {
      const body = {
        token: path[2],
      };
      /* AXIOS INTERCEPTOR */
      axios.interceptors.response.use(
        (res) => res,
        (err) => {
          if (err.response.status === 502) {
            window.location.href = "/service-unavailable";
          }
          return Promise.reject(err);
        }
      );
      axios
        .post(`${BASE_URL}cportal/public/validate`, body)
        .then((res) => (window.location.href = res.data.data.path))
        .catch((err) => {
          window.location.pathname = "/link-expired";
        });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App" style={{ width: "100%" }}>
        {/* <header className="App-header" /> */}
        {/* <MainHeader className="" /> */}
        <div className="content">
          <Router>
            <Routes>
              {/* <Route
                exact
                path="/welcome"
                element={
                  <div className="w-100">
                    <MainHeader className="" />
                    <HomePage />
                  </div>
                }
              /> */}

              {/* <Route path="bulk-upload" element={<BulkUpload />} /> */}
              <Route
                path="public/certificate/verify/:id"
                element={<VerifyCertificate />}
              />
              <Route path="link-expired" element={<LinkExpired />} />
              {/* <Route
                path="certificate/new-template"
                element={<AddTemplate />}
              /> */}
              {/* <Route path="payments/summary" element={<PaymentSummary />} /> */}
              {/* <Route path="bulk-upload/list" element={<BulkUploadList />} /> */}
              {/* <Route path="request-access" element={<RequestAccess />} /> */}
              {/* <Route
                path="modify-certificate"
                element={<ModifyCertificate />}
              /> */}
              {/* <Route
                path="certificate/upload"
                element={<UploadCertificate />}
              /> */}

              {/* <Route path="batch/list" element={<BatchList />} /> */}
              <Route path="/" element={<WelcomePage />} />
              {/* <Route path="Admin" element={<Admin />} /> */}
              {/* new page */}
              <Route
                path="/create-account"
                element={
                  <div className="w-100">
                    <MainHeader className="" />
                    <CreateAccount />
                  </div>
                }
              />

              {/* ADMIN ROUTES */}
              <Route
                path="/admin/users"
                element={
                  <AdminPrivateRoute>
                    <Users />
                  </AdminPrivateRoute>
                }
              />
              <Route
                path="/admin/user-request-form"
                element={
                  <AdminPrivateRoute>
                    <UserRequestForm />
                  </AdminPrivateRoute>
                }
              />


              {/* Wallet ADmin Section */}

              <Route path="/admin" element={<FullLayoutComponent />}>
                <Route index element={<LoginAdmin />} />
                <Route path="reset-password" element={<ResetPasswordScreen />} />
                <Route path="forgot-password" element={<ForgotpasswordScreen />} />
              </Route>


              <Route path="/admin/dashboard/" element={<AdminLayout />}>
                <Route index element={<AdminDashboard />} />
                <Route path="user-management" element={<UserManagement />} />
                <Route path="all_department" element={<AllDepartment />} />
              </Route>






              {/* USER ROUTES */}
              <Route
                path="certificate/details"
                element={
                  <UserPrivateRoute>
                    <CertificateDetails />
                  </UserPrivateRoute>
                }
              />

              {/* NOT FOUND */}
              {/* <Route path="*" element={<Navigate to="/" />} /> */}

              {/* SERVICE UNAVAILABLE */}
              <Route
                path="/service-unavailable"
                element={<ServiceUnavailable />}
              />
            </Routes>
          </Router>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
