import React, { useState, useEffect } from "react";
import { AiFillStepBackward, AiFillStepForward } from "react-icons/ai";

export const MisPaginationBar = ({
  elementsPerPage,
  totalElelemt,
  onPaginationChange,
}) => {
  const [counter, setCounter] = useState(1);
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalElelemt / elementsPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    const value = elementsPerPage * counter;

    //
    //
    //onPaginationChange(value-elementsPerPage , value)
  }, [counter]);
  return (
    <div className="col-md-12 mt-5 mb-4 navigationBarScss">
      <div className="navigation-dataNumber">
        <div style={{ display: "flex", marginLeft: "2%", marginTop: "5%" }}>
          <label>{elementsPerPage}</label>
          &nbsp; &nbsp;
          <label>Items Per Page</label>
        </div>
      </div>
      <div className="pagination-pagenumber" style={{ marginLeft: "45%" }}>
        <div className="pagination-paggination" style={{ display: "flex" }}>
          <AiFillStepBackward
            className="btn-btn-primary"
            onClick={() => {
              if (counter > 1) {
                setCounter(counter - 1);
                onPaginationChange(counter - 1);
              }
            }}
            style={{
              cursor: "pointer",
              backgroundColor: "transparent",
            }}
          ></AiFillStepBackward>
          <div style={{ display: "flex", alignItems: "space-between" }}>
            {pageNumbers.map((index) => (
              <button
                key={index}
                className={`${counter === index ? "active" : "inactive"}`}
                style={{
                  border: "2px solid #902A2B60",
                  marginLeft: "3px",
                  borderRadius: "5px",
                  backgroundColor: `${
                    counter === index ? "#902A2B60" : "white"
                  }`,
                }}
                id={index}
                onClick={() => {
                  setCounter(index);
                  onPaginationChange(index);
                }}
              >
                {index}
              </button>
            ))}
          </div>

          <AiFillStepForward
            className="btn-btn-primary"
            onClick={() => {
              if (counter < pageNumbers.length) {
                setCounter(counter + 1);
                onPaginationChange(counter + 1);
              }
            }}
            style={{
              cursor: "pointer",
              backgroundColor: "transparent",
              bordern: "none",
            }}
          ></AiFillStepForward>
        </div>
      </div>
    </div>
  );
};
