import React from "react";
import { withStyles } from "@mui/styles";
import FieldRenderer, { FIELD_TYPES } from "components/FormField";
import AppCard from "components/AppCard";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    color: theme.palette.text.primary,
    marginBottom: "40px",
  },
  summary: {
    width: "50%",
    textAlign: "left",
    padding: "20px",
  },
  paymentMode: {
    width: "45%",
  },
  paymentCard: {
    padding: "20px",
  },
  container: {
    display: "flex",
    width: "90%",
    padding: "5%",
    justifyContent: "space-between",
  },
  fieldContainer: {
    marginBottom: "30px",
  },
  radioGroup: {
    display: "flex",
    marginBottom: "30px",
  },
  formControl: {
    background: "white",
  },
  keyValue: {
    display: "flex",
    textAlign: "left",
    margin: "0 0 30px 30px",
  },
  label: {
    width: "50%",
    fontSize: "18px",
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  value: {
    width: "40%",
    fontSize: "16px",
    color: theme.palette.text.primary,
  },
  cardDetails: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardDetailField: {
    width: "30%",
  },
  payBtn: {
    textAlign: "right !important",
    margin: "32px 0 20px auto !important",

    "& a": {
      borderRadius: "18px !important",
      border: "1px solid",
      padding: "6px 12px",
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  },
  colon: {
    fontWeight: 600,
  },
});

class PaymentSummary extends React.Component {
  state = {
    paymentModes: [
      { label: "Debibt/ Credit Card", value: "card" },
      { label: "UPI", value: "upi" },
      { label: "Net Banking", value: "internet" },
    ],
  };

  render() {
    const { classes } = this.props;
    const { paymentModes } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.summary}>
          <div className={classes.title}>Payment Summary</div>
          <div className={classes.keyValue}>
            <div className={classes.label}>Certificate Type</div>
            <div className={classes.value}>
              <span className={classes.colon}>:</span> Post Graduate
            </div>
          </div>
          <div className={classes.keyValue}>
            <div className={classes.label}>Year</div>
            <div className={classes.value}>
              <span className={classes.colon}>:</span> 2002
            </div>
          </div>
          <div className={classes.keyValue}>
            <div className={classes.label}>Total Amount</div>
            <div className={classes.value}>
              <span className={classes.colon}>:</span> 1000
            </div>
          </div>
          <div className={classes.keyValue}>
            <div className={classes.label}>Handling Charges</div>
            <div className={classes.value}>
              <span className={classes.colon}>:</span> 1000
            </div>
          </div>
        </div>
        <div className={classes.paymentMode}>
          <AppCard containerStyle={{ width: "100%" }}>
            <div className={classes.paymentCard}>
              <div className={classes.title}>Select Payment Method</div>
              <div>
                <FieldRenderer
                  classes={{
                    container: classes.radioGroup,
                    formControlProps: classes.formControl,
                  }}
                  field={{
                    type: FIELD_TYPES.radio,
                    radioOptions: paymentModes,
                  }}
                />
              </div>
              <FieldRenderer
                classes={{
                  container: classes.fieldContainer,
                }}
                field={{ type: FIELD_TYPES.textInput }}
                label="Enter Your Card Number"
              />
              <FieldRenderer
                classes={{
                  container: classes.fieldContainer,
                }}
                field={{ type: FIELD_TYPES.textInput }}
                label="Card Holder Name"
              />
              <div className={classes.cardDetails}>
                <FieldRenderer
                  classes={{ container: classes.cardDetailField }}
                  placeholder="Expiry Month"
                  field={{ type: FIELD_TYPES.textInput }}
                />
                <FieldRenderer
                  classes={{ container: classes.cardDetailField }}
                  placeholder="Expiry Year"
                  field={{ type: FIELD_TYPES.textInput }}
                />
                <FieldRenderer
                  classes={{ container: classes.cardDetailField }}
                  placeholder="CVV"
                  field={{ type: FIELD_TYPES.textInput }}
                />
              </div>
              <div className={classes.payBtn}>
                {/* <Button variant="outlined" onClick={this.goToCertificateDetails}>Pay Now</Button> */}
                <Link to="/certificate/details">Pay Now</Link>
              </div>
            </div>
          </AppCard>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PaymentSummary);
