import React from "react";
import User from "../../assets/Group 643.png";
import rec1 from "../../assets/rec1.png";
import rec2 from "../../assets/rec2.png";
import rec3 from "../../assets/rec3.png";
import { Chart as ChartJS, Tooltip, Title, ArcElement, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { MisPaginationBar } from "../../pages/Pagination/Paginationbar";
import Header from "../../components/MainHeader/AdminHeader";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getAllUser, updateUser } from "../../store/Admin/action";
ChartJS.register(Tooltip, Title, ArcElement, Legend);

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userall: true,
      blockuse: false,
      penduser: false,
      userPerPage: 5,
      dummydata: [],
      userDisplayed: [],
      data: [],
      pieData: {
        datasets: [
          {
            data: [2779, 10, 23],
            backgroundColor: ["#BFDDA6", "#F68D88", "#497295"],
          },
        ],
      },
    };
  }

  allUSers = (data) => {
    if (data === "block1") {
      this.setState({
        userall: true,
        blockuse: false,
        penduser: false,
      });
    }
    if (data === "block2") {
      this.setState({
        userall: false,
        blockuse: true,
        penduser: false,
      });
    }
    if (data === "block3") {
      this.setState({
        userall: false,
        blockuse: false,
        penduser: true,
      });
      // let temp =this.state;
      // let datas = [];
      // if(this.state.data.length !== 0){
      //     for(var i=0;i<this.state.data.length;i++){
      //         if(this.state.data[i].status == "PENDING"){
      //             datas.push(this.state.data[i]);
      //         }
      //     }
      // }
      //
      // temp.data=datas
      // temp.userDisplayed =datas.splice(0,this.state.userPerPage)
      // this.setState(temp)
    }
  };

  componentDidMount() {
    this.props.getAllUser();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.AllUserData != this.props.AllUserData) {
      let temp = this.state;
      temp.data = this.props.AllUserData;
      temp.userDisplayed = temp.data.slice(0, this.state.userPerPage);
      let blockCount = 0;
      let pendingCount = 0;
      if (this.props.AllUserData !== undefined) {
        for (var i = 0; i < this.props.AllUserData.length; i++) {
          if (this.props.AllUserData[i].status === "BLOCK") {
            blockCount++;
          }
          if (this.props.AllUserData[i].status === "PENDING") {
            pendingCount++;
          }
        }
      }

      temp.blockData = blockCount;
      temp.pendingData = pendingCount;
      this.setState(temp);
    }
  }

  handlePaginate = (currentPage) => {
    const indexOfLastData = currentPage * this.state.userPerPage;
    const indexOfFirstData = indexOfLastData - this.state.userPerPage;
    const displayData = this.state.dummydata.slice(
      indexOfFirstData,
      indexOfLastData
    );
    this.setState({
      ...this.state,
      userDisplayed: displayData,
    });
  };

  userStatus = (data, data1) => {
    if (data === "PENDING") {
      const body = {
        id: data1,
        status: "ACTIVE",
      };

      this.props.updateUser(body);
    }
  };

  render() {
    return (
      <div className="container-fluid" style={{ backgroundColor: "white" }}>
        <Header />
        <div
          style={{
            marginTop: "50px",
            marginLeft: "92px",
            width: "1357px",
            height: "357px",
            boxShadow: "0px 3px 16px #00000029",
            borderRadius: "5px",
            opacity: "1",
          }}
        >
          <div>
            <div style={{ display: "flex" }}>
              <img
                style={{
                  marginTop: "19px",
                  marginLeft: "40px",
                  width: "31px",
                  height: "31px",
                  opacity: "1",
                }}
                src={User}
              />
              <p
                style={{
                  marginTop: "19px",
                  marginLeft: "10px",
                  width: "61px",
                  height: "25px",
                  opacity: "1",
                  fontWeight: "bold",
                  color: "#000000",
                }}
              >
                User’s
              </p>
            </div>
          </div>
          <hr style={{ height: "2px" }} />
          <div>
            <div className="row">
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-4">
                    <div style={{ marginTop: "35%" }}>
                      <center>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "#000000",
                            fontSize: "18px",
                          }}
                        >
                          Total Users
                        </p>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "#902A2B",
                            fontSize: "25px",
                          }}
                        >
                          {this.state.data.length}
                        </p>
                      </center>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div style={{ marginTop: "35%" }}>
                      <center>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "#000000",
                            fontSize: "18px",
                          }}
                        >
                          Blocked Users
                        </p>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "#F68D88",
                            fontSize: "25px",
                          }}
                        >
                          {this.state.blockData}
                        </p>
                      </center>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div style={{ marginTop: "35%" }}>
                      <center>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "#000000",
                            fontSize: "18px",
                          }}
                        >
                          Pending Approval
                        </p>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "#497295",
                            fontSize: "25px",
                          }}
                        >
                          {this.state.pendingData}
                        </p>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div
                  style={{
                    boxShadow: "0px 3px 16px #00000029",
                    marginTop: "-1%",
                    borderRadius: "5px",
                    opacity: "1",
                    height: "254px",
                    width: "476px",
                  }}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          marginTop: "5%",
                          marginLeft: "14%",
                        }}
                      >
                        <Doughnut
                          data={this.state.pieData}
                          options={{
                            responsive: true,
                            maintainAspectRatio: true,
                            legend: {
                              display: false,
                            },
                          }}
                        ></Doughnut>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div style={{ marginTop: "27%", marginLeft: "8%" }}>
                        <div>
                          <img src={rec1} />{" "}
                          <span
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                          >
                            Approved User
                          </span>
                        </div>
                        <br />
                        <div style={{ marginLeft: "-17%" }}>
                          <img src={rec2} />{" "}
                          <span
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                          >
                            Pending
                          </span>
                        </div>
                        <br />
                        <div>
                          <img src={rec3} />{" "}
                          <span
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                          >
                            Blocked Users
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div style={{ height: "650px" }}>
          <div
            style={{
              marginTop: "50px",
              marginLeft: "92px",
              width: "1357px",
              marginBottom: "10%",
              height: "557px",
              boxShadow: "0px 3px 16px #00000029",
              borderRadius: "5px",
              opacity: "1",
            }}
          >
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <center>
                      <a onClick={() => this.allUSers("block1")}>
                        <p
                          style={{
                            marginTop: "6%",
                            padding: "12px 18px",
                            borderBottom:
                              this.state.userall === true
                                ? "5px solid #902A2B"
                                : "1px solid white",
                          }}
                        >
                          All Users
                        </p>
                      </a>
                    </center>
                  </div>
                  <div className="col-md-4">
                    <center>
                      <a onClick={() => this.allUSers("block2")}>
                        <p
                          style={{
                            marginTop: "6%",
                            padding: "12px 18px",
                            borderBottom:
                              this.state.blockuse === true
                                ? "5px solid #902A2B"
                                : "1px solid white",
                          }}
                        >
                          Blocked Users
                        </p>
                      </a>
                    </center>
                  </div>
                  <div className="col-md-4">
                    <center>
                      <a onClick={() => this.allUSers("block3")}>
                        <p
                          style={{
                            marginTop: "6%",
                            padding: "12px 18px",
                            borderBottom:
                              this.state.penduser === true
                                ? "5px solid #902A2B"
                                : "1px solid white",
                          }}
                        >
                          Pending Users
                        </p>
                      </a>
                    </center>
                  </div>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
            {this.state.userall === true && (
              <div>
                <br />

                <div
                  className="row table-responsive"
                  style={{ width: "1348px" }}
                >
                  <table
                    className="igr-batch-id-all-documents-table"
                    style={{ width: "1310px", marginLeft: "27px" }}
                  >
                    <tbody>
                      <tr
                        className="theader"
                        style={{ backgroundColor: "#902A2B60", height: "50px" }}
                      >
                        <td>ID</td>
                        <td>Name</td>
                        <td>Role</td>
                        <td>Status</td>
                        <td>User Id</td>
                      </tr>
                      {this.state.data.length != 0 &&
                        this.state.userDisplayed.map((items, indx) => (
                          <tr
                            className={
                              indx % 2 == 0 ? "all-users" : "blocked-user"
                            }
                            style={{
                              height: "4rem",
                              background: indx % 2 ? "#902A2B05" : "#fff",
                            }}
                          >
                            <td
                              className="idHighLighter"
                              style={{
                                fontSize: "small",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              <span>{items.id}</span>
                            </td>
                            <td>{items.name}</td>
                            <td
                              style={{
                                color: "rgba(55, 125, 34,0.8)",
                                cursor: "pointer",
                                fontSize: "small",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {items.role}
                            </td>
                            <td
                              style={{
                                color: "rgba(55, 125, 34,0.8)",
                                cursor: "pointer",
                                fontSize: "small",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {/* <BsCircleFill
                                                color={'#377D22'}
                                                size={8}
                                                ></BsCircleFill>{' '} */}
                              {items.status}
                            </td>
                            <td>
                              <a
                                onClick={() =>
                                  this.userStatus(items.status, items._id)
                                }
                                style={{
                                  display: "block",
                                  margin: "auto",
                                  cursor: "pointer",
                                  width: "100px",
                                  border: "1px solid #902A2C",
                                  backgroundColor: "transparent",
                                  borderRadius: "12px",
                                  color: "#902A2B",
                                  fontSize: "small",
                                  onMouseOver: "this.style.color='red'",
                                  onMouseOut: "this.style.color='green'",
                                }}
                              >
                                {items.status === "ACTIVE"
                                  ? "Block"
                                  : "Unblock"}
                              </a>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {this.state.blockuse === true && (
              <div>
                <br />

                <div className="row table-responsive">
                  <table
                    className="igr-batch-id-all-documents-table"
                    style={{ width: "1320px", marginLeft: "27px" }}
                  >
                    <tbody>
                      <tr
                        className="theader"
                        style={{ backgroundColor: "#902A2B60", height: "50px" }}
                      >
                        <td>ID</td>
                        <td>Name</td>
                        <td>Role</td>
                        <td>onboard</td>
                        <td>User Id</td>
                      </tr>
                      {this.state.data.length != 0 &&
                        this.state.userDisplayed.map((items, indx) => (
                          <>
                            {items.status === "BLOCKED" && (
                              <tr
                                className={
                                  indx % 2 == 0 ? "all-users" : "blocked-user"
                                }
                                style={{
                                  height: "4rem",
                                  background: indx % 2 ? "#902A2B05" : "#fff",
                                }}
                              >
                                <td
                                  className="idHighLighter"
                                  style={{
                                    fontSize: "small",
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span>{items.id}</span>
                                </td>
                                <td>{items.name}</td>
                                <td
                                  style={{
                                    color: "rgba(55, 125, 34,0.8)",
                                    cursor: "pointer",
                                    fontSize: "small",
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {items.role}
                                </td>
                                <td
                                  style={{
                                    color: "rgba(55, 125, 34,0.8)",
                                    cursor: "pointer",
                                    fontSize: "small",
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {items.onboard}
                                </td>
                                <td>
                                  <a
                                    style={{
                                      display: "block",
                                      margin: "auto",
                                      cursor: "pointer",
                                      width: "100px",
                                      border: "1px solid #902A2C",
                                      backgroundColor: "transparent",
                                      borderRadius: "12px",
                                      color: "#902A2B",
                                      fontSize: "small",
                                      onMouseOver: "this.style.color='red'",
                                      onMouseOut: "this.style.color='green'",
                                    }}
                                  >
                                    {items.status === "ACTIVE"
                                      ? "Block"
                                      : "Unblock"}
                                  </a>
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {this.state.penduser === true && (
              <div>
                <br />

                <div className="row table-responsive">
                  <table
                    className="igr-batch-id-all-documents-table"
                    style={{ width: "1320px", marginLeft: "27px" }}
                  >
                    <tbody>
                      <tr
                        className="theader"
                        style={{ backgroundColor: "#902A2B60", height: "50px" }}
                      >
                        <td>ID</td>
                        <td>Name</td>
                        <td>Role</td>
                        <td>onboard</td>
                        <td>User Id</td>
                      </tr>
                      {this.state.data.length != 0 &&
                        this.state.userDisplayed.map((items, indx) => (
                          <>
                            {items.status === "PENDING" && (
                              <tr
                                className={
                                  indx % 2 == 0 ? "all-users" : "blocked-user"
                                }
                                style={{
                                  height: "4rem",
                                  background: indx % 2 ? "#902A2B05" : "#fff",
                                }}
                              >
                                <td
                                  className="idHighLighter"
                                  style={{
                                    fontSize: "small",
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span>{items.id}</span>
                                </td>
                                <td>{items.name}</td>
                                <td
                                  style={{
                                    color: "rgba(55, 125, 34,0.8)",
                                    cursor: "pointer",
                                    fontSize: "small",
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {items.role}
                                </td>
                                <td
                                  style={{
                                    color: "rgba(55, 125, 34,0.8)",
                                    cursor: "pointer",
                                    fontSize: "small",
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {items.onboard}
                                </td>
                                <td>
                                  <span>pending</span>
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            <br />
            <MisPaginationBar
              elementsPerPage={this.state.userPerPage}
              totalElelemt={this.state.data.length}
              onPaginationChange={this.handlePaginate}
            ></MisPaginationBar>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    AllUserData: state.Admin.getAllUser_response.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllUser,
      updateUser,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Admin);
