import React from "react";
import { FileDrop } from "react-file-drop";
import "./filedrop.css";

export const FileDropComponent = (props) => {
  const { styles, children } = props;

  const handleFileDrop = (files, event) => {};
  return (
    <div style={styles}>
      <div style={{ height: "100%" }}>
        <FileDrop onDrop={(files, event) => handleFileDrop(files, event)}>
          {children}
        </FileDrop>
      </div>
    </div>
  );
};
