import { combineReducers } from "redux";
import Admin from "./Admin/reducer";
import WalletAdmin from "./WalletAdmin/reducer"
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["Admin","WalletAdmin"],
};

const authPersist_Admin_Config = { key: "Admin", storage: sessionStorage };
const authPersist_WalletAdmin_Config = { key: "WalletAdmin", storage: sessionStorage };

const rootReducer = combineReducers({
  Admin: persistReducer(authPersist_Admin_Config, Admin),
  // eslint-disable-next-line no-undef
  WalletAdmin: persistReducer(authPersist_WalletAdmin_Config, WalletAdmin),
});
export default persistReducer(persistConfig, rootReducer);
