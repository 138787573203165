import React from "react";
import { makeStyles } from "@mui/styles";
import { ReactComponent as BulkAdd } from "assets/BulkAdd.svg";
import FieldRenderer, { FIELD_TYPES } from "components/FormField";
import ContentHeader from "components/ContentHeader";
import AppCard from "components/AppCard";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  templateContainer: {
    width: "80%",
    height: "80%",
    margin: "auto",
  },
  fieldContainer: {
    maxWidth: "300px",
    flex: 1,
  },
  content: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "40px",
  },
  requestButton: {
    marginTop: "25%",
    marginRight: "5%",
    textAlign: "right",
    "& button": {
      borderRadius: "16px",
    },
  },
}));

const RequestAccess = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.templateContainer}>
      <AppCard containerStyle={{ width: "100%", height: "100%" }}>
        <ContentHeader
          title="Modify A Template"
          HeaderIcon={BulkAdd}
          showBackIcon
        />
        <div className={classes.content}>
          <FieldRenderer
            classes={{ container: classes.fieldContainer }}
            field={{ type: FIELD_TYPES.select }}
            label="Select Institution"
          />

          <FieldRenderer
            classes={{ container: classes.fieldContainer }}
            field={{ type: FIELD_TYPES.select }}
            label="Select Certificate Type"
          />
        </div>
        <div className={classes.requestButton}>
          <Button variant="outlined" color="primary">
            Request For Access
          </Button>
        </div>
      </AppCard>
    </div>
  );
};

export default RequestAccess;
