export const GET_ALL_USER = "GET_ALL_USER";
export const GET_ALL_USER_ERROR = "GET_ALL_USER_ERROR";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_USER_DATA_ERR = "UPDATE_USER_DATA_ERR";

// new types are created
export const LOGIN = "LOGIN";
export const OTP_VERIFICATION = "OTP_VERIFICATION";
export const REGISTER_USER = "REGISTER_USER";
export const GET_ALL_USER_ADMIN = "GET_ALL_USER_ADMIN";
export const UPDATE_USER_BLOCKED = "UPDATE_USER_BLOCKED";
export const UPDATE_USER_APPROVED = "UPDATE_USER_APPROVED";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESEND_PASSWORD = "RESEND_PASSWORD";
export const VERIFY_FORGOT_PASSWORD_OTP = "VERIFY_FORGOT_PASSWORD_OTP";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_REQUEST_USER_FORM = "GET_REQUEST_USER_FORM";
export const LOGOUT_SESSION = "LOGOUT_SESSION";
