import React, { useState, useEffect } from "react";
import AppCard from "../../components/AppCard";
import { ReactComponent as Protected } from "assets/Protected.svg";
import { ReactComponent as Register } from "assets/Register.svg";
import { ReactComponent as DropFile } from "assets/DropFile.svg";
import { makeStyles } from "@mui/styles";
import { Modal } from "@mui/material";
import { ReactComponent as Logo } from "assets/logo.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import VerifyCertificate from "pages/Certificate/verify";
import grp679 from "../../assets/Group 674.png";
// import RCG from "react-captcha-generator";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import MainHeader from "../../components/MainHeader/index";
import { useDispatch, useSelector } from "react-redux";
import { getLogin, updateForgotPassword } from "store/action";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { CgLogIn } from "react-icons/cg";
import { IoMdRefresh } from "react-icons/io";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import OtpVerify from "./OtpVerify";
import * as CryptoJS from "crypto-js";
import axios from "axios";
import { BASE_URL } from "utilities/config";

import niIcon from "../../assets/ni-icon.png";

const MySwal = withReactContent(Swal);

// import MainHeader from '../../components/HeaderTabs/index'

const useStyles = makeStyles((theme) => ({
  homeContainer: {
    display: "flex",
    justifyContent: "space-around",
    // padding: "2%",
  },
  homeCards: {
    // marginLeft: "20%",
    display: "flex",
    // width: "110%",
  },
  welcome: {
    fontSize: "20px",
    color: theme.palette.primary.main,
    textAlign: "left",
    fontWeight: "500",
    // marginLeft: "14%",
  },
  tnLogo: {
    "& img": {
      width: "54px",
    },
    marginRight: "16px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    fontSize: "22px",
    fontWeight: "bold",
    color: theme.palette.text.primary,
    margin: "32px 73px",
  },
  menuLink: {
    fontSize: "18px",
    fontWeight: 500,
    color: theme.palette.text.primary,
    padding: "14px 32px 14px 12px",
    border: "1px solid #902a2b4d",
    flex: 1,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",

    "&:first-child": {
      // border: "none",
    },

    "& svg": {
      height: "36px",
    },
  },
  cardTitle: {
    display: "flex",
    alignItems: "center",
    padding: "0 32px",
    marginTop: "20px",
  },
  linksContainer: {
    margin: "40px 60px",
  },
  cardTitleText: {
    fontSize: "24px",
    color: theme.palette.text.primary,
    fontWeight: "bold",
    marginLeft: "12px",
  },
  modalContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  modalSize: {
    width: "80%",
    height: "80%",
    backgroundColor: "white",
    margin: "auto",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    height: "64px",
    backgroundColor: "#902a2b",
  },
  modalLogo: {
    height: "48px",
  },
  modalTitle: {
    color: "white",
    fontSize: "18px",
    fontWeight: "bold",
  },
  closeIcon: {
    marginLeft: "auto",
    color: "white",
    cursor: "pointer",
  },
  secAppCard: {
    // marginLeft: "170%",
    // marginTop: "-95%",
    marginBottom: "10%",
    backgroundColor: "white",
    borderRadius: "10px",
  },
}));

const WelcomePage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [captcha, setCaptcha] = useState();
  const [originalCaptcha, setOriginalCaptcha] = useState("");
  const [otpVerificationStatus, setOtpVerificationStatus] = useState(false); // for otp panel, initially the value should be false
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false);
  const [isPasswordVisiable, setIsPassowrdVisiable] = useState(false);
  const [newCapcha, setNewCaptcha] = useState("");
  const [newCapchaId, setNewCaptchaId] = useState("");

  const loginData = useSelector((state) => state.Admin.getLoginData);
  const forgotPasswordData = useSelector(
    (state) => state.Admin.forgotPasswordData
  );

  useEffect(() => {
    // if (forgotPasswordData.status === 200) {
    //   setOtpVerificationStatus((prev) => !prev);
    //   setForgotPasswordStatus((prev) => !prev);
    // }
  }, [forgotPasswordData]);

  const encapsulateData = (encapData) => {
    const encryptConfigs = {
      key: "t700#zkrF@db0705",
      iv: "i700#zkrF@db0705",
    };

    var key = CryptoJS.enc.Latin1.parse(encryptConfigs.key);
    var iv = CryptoJS.enc.Latin1.parse(encryptConfigs.iv);
    var encryptedData = CryptoJS.AES.encrypt(encapData, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    encryptedData = encryptedData.toString();
    //
    return encryptedData;
  };

  const result = (text) => {
    //
    setOriginalCaptcha(text);
  };

  const onChangeInput = (event) => {
    //
    const name = event.target.name;
    const value = event.target.value;
    if (name === "email") {
      setUsername(value);
    }
    if (name === "password") {
      setPassword(value.replace(/[^A-Za-z0-9!@#$%-&]/gi, ""));
    }
    if (name === "captcha") {
      setCaptcha(value);
    }
  };

  const onClickSubmit = async (event) => {
    event.preventDefault();
    if (forgotPasswordStatus === true) {
      setForgotPasswordStatus((prev) => !prev);
    }

    //
    if (captcha === newCapcha) {
      const body = {
        user: {
          email: encapsulateData(username),
          password: encapsulateData(password),
          captchaid: newCapchaId,
          captcha: newCapcha,
        },
      };
      //
      await dispatch(getLogin(body, setOtpVerificationStatus));
    } else {
      Swal.fire({
        icon: "error",
        title: "Captcha!",
        text: `Incorrect Captcha!`,
      });
    }

    //
    // if (loginData.status === 200) {
    //
    //   if (loginData.data.user.role === "admin") {
    //
    //     // navigate("admin/users");
    //   }
    // }
  };

  const onClickForgotPassword = () => {
    if (username === "" || username.includes("@") === false) {
      Swal.fire({
        icon: "error",
        title: "Invalid Email!",
        text: `Please provide your email!`,
      });
    } else {
      //
      const body = {
        email: encapsulateData(username),
      };
      dispatch(
        updateForgotPassword(
          body,
          setForgotPasswordStatus,
          setOtpVerificationStatus
        )
      );

      // setOtpVerificationStatus((prev) => !prev);
      // setForgotPasswordStatus((prev) => !prev);

      // if (forgotPasswordStatus === true) {
      //   setForgotPasswordStatus((prev) => !prev);
      // }
    }
  };

  // function resendOtp() {
  //   //
  //   if (username === "" || !username.includes("@")) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Unable to Send OTP",
  //       text: "Invalid Email address",
  //     });
  //     return;
  //   }

  //   const body = { email: encapsulateData(username) };

  //   dispatch(updateForgotPassword(body));
  // }

  const getCaptcha = () => {
    const url = `${BASE_URL}cportal/captcha`;
    /* AXIOS INTERCEPTOR */
    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        if (err.response.status === 502) {
          window.location.href = "/service-unavailable";
        }
        return Promise.reject(err);
      }
    );
    axios
      .get(url)
      .then((res) => {
        setNewCaptcha(res.data.data.captcha);
        setNewCaptchaId(res.data.data.id);
      })
      .catch((err) => {
        Swal.fire({
          icon: "info",
          title: "Captcha!",
          text: err.response.data,
          confirmButtonText: "OK",
        });
      });
  };

  useEffect(() => {
    getCaptcha();
  }, []);

  // const onChangeRememberMe = (event) => {
  //
  //   if (event.target.checked === true) {
  //     localStorage.setItem("username", username);
  //     localStorage.setItem("password", password);
  //   }
  // };

  //

  //

  const onClickSignUp = async () => {
    if (forgotPasswordStatus === true) {
      await setForgotPasswordStatus((prev) => !prev);
    }
    if (otpVerificationStatus === true) {
      await setOtpVerificationStatus(false);
    }
    await navigate("/create-account");
  };

  return (
    <div style={{ maxWidth: "100vw" }}>
      <MainHeader />
      <div className={classes.homeContainer}>
        <div className="d-none d-lg-block">
          <div className={classes.welcome}>Welcome !</div>
          <div className={classes.title}>
            <span className={classes.tnLogo}>
              <img src="tnega.png" alt="tnega" />
            </span>
            <div>Certificate Verification Portal</div>
          </div>
          <div>
            <div className={classes.homeCards}>
              <AppCard>
                <div className={classes.cardTitle}>
                  <Protected />{" "}
                  <span className={classes.cardTitleText}>
                    Verification Of Certificates
                  </span>
                </div>
                <div className={classes.linksContainer}>
                  <div
                    className={classes.menuLink}
                    // onClick={() => setModalOpen(true)}
                  >
                    <DropFile /> Drop or Upload a Certificate To Verify
                  </div>
                  <div className={classes.menuLink}>
                    <Register /> Register as Issuer / Verifier
                  </div>
                </div>
              </AppCard>
            </div>
          </div>
        </div>
        <div>
          {/* old login card */}
          {/* <div className={classes.secAppCard}>
          <div
            style={{
              marginTop: "26px",
              marginLeft: "-108px",
              width: "400px",
              height: "600px",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "11px",
              opacity: "1",
              backgroundColor: "#FFFFFF",
            }}
          >
            <div style={{ display: "flex" }}>
              <img
                src={grp679}
                style={{
                  width: "30px",
                  height: "30px",
                  marginLeft: "2%",
                  marginTop: "20px",
                }}
              />{" "}
              <p
                style={{
                  fontSize: "20px",
                  marginTop: "18px",
                  marginLeft: "20px",
                  fontWeight: "bold",
                }}
              >
                Sign In
              </p>
            </div>
            <br />
            <br />
            <div>
              <label style={{ fontSize: "14px", marginLeft: "-55%" }}>
                Select Role
              </label>
              <br />
              <select
                type="text"
                name="seleRole"
                style={{
                  color: "black",
                  width: "75%",
                  border: "1px solid rgba(216,216,216,1)",
                  borderRadius: "4px",
                  fontSize: "15px",
                  height: "2rem",
                  boxShadow: "0px 3px 6px #00000029",
                }}
              >
                <option selected></option>
                <option>Certificate Verifier</option>
                <option>Certificate Issuer</option>
              </select>
              <br />
              <br />
              <label style={{ fontSize: "14px", marginLeft: "-35%" }}>
                Enter Your Username
              </label>
              <br />
              <input
                onChange={onChangeInput}
                style={{
                  color: "black",
                  width: "75%",
                  border: "1px solid rgba(216,216,216,1)",
                  borderRadius: "4px",
                  fontSize: "15px",
                  height: "2rem",
                  boxShadow: "0px 3px 6px #00000029",
                }}
                placeholder=""
                name="username"
                id="username"
              />
              <br />
              <br />
              <label style={{ fontSize: "14px", marginLeft: "-35%" }}>
                Enter Your Password
              </label>
              <br />
              <input
                onChange={onChangeInput}
                style={{
                  color: "black",
                  width: "75%",
                  border: "1px solid rgba(216,216,216,1)",
                  borderRadius: "4px",
                  fontSize: "15px",
                  height: "2rem",
                  boxShadow: "0px 3px 6px #00000029",
                }}
                placeholder=""
                name="password"
                id="password"
              />
              <br />
              <div style={{ marginLeft: "-47%" }}>
                <RCG result={result} />
              </div>
              <label style={{ fontSize: "14px", marginLeft: "-35%" }}>
                Enter Captcha
              </label>
              <br />
              <input
                style={{
                  color: "black",
                  width: "75%",
                  border: "1px solid rgba(216,216,216,1)",
                  borderRadius: "4px",
                  fontSize: "15px",
                  height: "2rem",
                  boxShadow: "0px 3px 6px #00000029",
                }}
                placeholder=""
                name="Captchas"
                id="Captchas"
              />
              <br />
              <div className="row" style={{ marginTop: "50px" }}>
                <div className="col-md-6">
                  <a
                    style={{
                      textDecoration: "underline",
                      lineHeight: "1.5em",
                      marginLeft: "-12%",
                    }}
                  >
                    Forgot Password ?
                  </a>
                </div>
                <div className="col-md-6">
                  <a
                    style={{
                      padding: "5px 10px",
                      border: "2px solid #902A2B",
                      color: "#902A2B",
                      borderRadius: "10px",
                      marginLeft: "55%",
                      boxShadow: "0px 3px 6px #00000029",
                      cursor: "pointer",
                    }}
                    onClick={onClickSubmit}
                  >
                    Submit
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
          {/* new login card */}

          {/*  */}
          {!otpVerificationStatus ? (
            <form
              onSubmit={onClickSubmit}
              className="w-100 pt-4"
              // style={{ padding: "3% 7% 1% 7%" }}
            >
              <div className="d-flex justify-content-end w-100">
                <div
                  className="px-3 py-5"
                  style={{
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "11px",
                    minWidth: "420px",
                    width: "40%",
                    borderBottom: "4px solid #902A2B",
                  }}
                >
                  <h5 className="d-flex align-items-center gap-3">
                    <span>
                      <CgLogIn
                        style={{
                          fontSize: "26px",
                          color: "#902A2B",
                          fontWeight: "700",
                        }}
                      />
                    </span>
                    Sign In
                  </h5>
                  <div
                    className="d-flex flex-column gap-2"
                    style={{ margin: "30px" }}
                  >
                    <div className="d-flex flex-column text-left">
                      <p
                        className="m-1"
                        style={{
                          textAlign: "left",
                          color: "#292828D8",
                          fontWeight: "500",
                        }}
                      >
                        Enter Your Mail ID
                      </p>
                      <input
                        value={username}
                        title="Please enter valid email!"
                        required
                        type="email"
                        name="email"
                        onChange={onChangeInput}
                        className="m-1 p-2"
                        placeholder="Enter your email"
                        style={{
                          backgroundColor: "#F7FAFC",
                          borderRadius: "11px",
                          border: "none",
                        }}
                      />
                    </div>

                    <div className="d-flex flex-column text-left">
                      <p
                        className="m-1"
                        style={{
                          textAlign: "left",
                          color: "#292828D8",
                          fontWeight: "500",
                        }}
                      >
                        Enter Your Password
                      </p>
                      <span
                        className="d-flex align-items-center m-1"
                        style={{
                          borderRadius: "5px",
                          width: "100%",
                          backgroundColor: "#f7fafc",
                        }}
                      >
                        <input
                          value={password}
                          autoComplete="off"
                          required
                          type={isPasswordVisiable ? "text" : "password"}
                          // title="Please enter your 10 digit mobile number!"
                          // pattern="[1-9]{1}[0-9]{9}"
                          name="password"
                          onChange={onChangeInput}
                          className="p-2 d-flex flex-grow-1"
                          placeholder="Enter your password"
                          style={{
                            backgroundColor: "#F7FAFC",
                            borderRadius: "11px",
                            border: "none",
                            // width: "90%",
                          }}
                        />
                        {isPasswordVisiable ? (
                          <AiFillEyeInvisible
                            onClick={() =>
                              setIsPassowrdVisiable((prev) => !prev)
                            }
                            style={{ fontSize: "20px", marginRight: "4px" }}
                          />
                        ) : (
                          <AiFillEye
                            onClick={() =>
                              setIsPassowrdVisiable((prev) => !prev)
                            }
                            style={{ fontSize: "20px", marginRight: "4px" }}
                          />
                        )}
                      </span>
                    </div>

                    {/* <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={onChangeRememberMe}
                      />
                      <label
                        className="form-check-label d-flex justify-content-start"
                        for="flexCheckDefault"
                      >
                        Remember Me
                      </label>
                    </div> */}

                    <div className="d-flex flex-column gap-2 text-left">
                      <p
                        className="m-1"
                        style={{
                          textAlign: "left",
                          color: "#292828D8",
                          fontWeight: "500",
                        }}
                      >
                        Enter The Captcha
                      </p>

                      {/* <CaptchaTest getCaptchaValue={result} /> */}
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <input
                          id="captchaInput"
                          className="captcha-input d-flex flex-grow-1"
                          // readOnly
                          pointerEvents="box-only"
                          value={newCapcha}
                          style={{
                            // backgroundColor: "#F7FAFC",
                            backgroundColor: "lightgrey",
                            borderRadius: "4px",
                            border: "none",
                            height: "40px",
                            padding: "10px",
                            fontSize: "20px",
                          }}
                          disabled
                        />
                        <div>
                          <IoMdRefresh
                            className="refresh-icon"
                            style={{ fontSize: "32px" }}
                            type="button"
                            onClick={getCaptcha}
                          />
                        </div>
                      </div>
                      <input
                        required
                        type="text"
                        placeholder="Enter The Captcha"
                        name="captcha"
                        style={{
                          padding: "7px",
                          borderRadius: "5px",
                          width: "100%",
                          backgroundColor: "#f7fafc",
                          border: "none",
                          fontSize: "17px",
                          outline: "none",
                          // pointerEvents: "none",
                        }}
                        onChange={onChangeInput}
                        value={captcha}
                        disabled={newCapcha === ""}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span
                      className="m-1"
                      style={{
                        textAlign: "left",
                        color: "#902A2B",
                        fontWeight: "500",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      onClick={onClickForgotPassword}
                    >
                      Forgot Password?
                    </span>
                    <button
                      className="px-3 py-2"
                      style={{
                        backgroundColor: "#902A2B",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#FFFFFF",
                        border: "none",
                        borderRadius: "25px",
                      }}
                      type="submit"
                    >
                      Log In
                    </button>
                  </div>
                  <div className="d-flex justify-content-start">
                    <span
                      className="m-1"
                      style={{
                        textAlign: "left",
                        color: "#902A2B",
                        fontWeight: "500",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      onClick={onClickSignUp}
                    >
                      Sign Up
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <span
                  className="m-4"
                  style={{
                    color: "#4984CA",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  Powered By Nambikkai Inaiyam
                </span>
                <img src={niIcon} style={{ width: "20px" }} />
              </div>
            </form>
          ) : (
            <OtpVerify
              username={username}
              forgotPasswordStatus={forgotPasswordStatus}
              setOtpVerificationStatus={setOtpVerificationStatus}
              setForgotPasswordStatus={setForgotPasswordStatus}
              // resendOtp={resendOtp}
            />
          )}
          {/* new login card */}
        </div>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={classes.modalContainer}>
            <div className={classes.modalSize}>
              <div className={classes.modalHeader}>
                <Logo className={classes.modalLogo} />{" "}
                <span className={classes.modalTitle}>Certificate Portal</span>
                <span className={classes.closeIcon}>
                  <CloseOutlinedIcon onClick={() => setModalOpen(false)} />
                </span>
              </div>
              <div className={classes.modalContent}>
                <VerifyCertificate />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default WelcomePage;
