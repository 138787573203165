import React from "react";
import { withStyles } from "@mui/styles";
import ContentHeader from "components/ContentHeader";
import { ReactComponent as RecycleIcon } from "assets/Recycle.svg";
import { ReactComponent as CsvFileIcon } from "assets/CsvFile.svg";
import FieldRenderer, { FIELD_TYPES } from "components/FormField";
import { Button } from "@mui/material";
import AppCard from "components/AppCard";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

const styles = (theme) => ({
  templateContainer: {
    width: "100%",
    padding: "30px",
    overflow: "auto",
  },
  content: {
    padding: "20px",
    paddingLeft: "80px",
  },
  fieldContainer: {
    maxWidth: "300px",
  },
  picklistContainer: {
    marginTop: "40px",
    paddingBottom: "20px",
  },
  submitButton: {
    marginTop: "40px",
    textAlign: "right",
    justifyContent: "right",
  },
  fieldsContainer: {
    display: "flex",
    paddingLeft: "40px",
  },
  field: { marginRight: "16px" },
  adornIcon: {
    width: "20px",
    height: "20px",
    backgroundColor: "#E3A0A1",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    "& svg": {
      width: "18px",
    },
  },
  inputField: {
    padding: "6px",
  },
  filledInput: {
    "& .MuiFilledInput-input": {
      padding: "6px !important",
      width: "100px",
    },
    "& .MuiFormControl-root": {
      width: "fit-content",
    },
    "& .MuiFilledInput-root": {
      width: "fit-content",
    },
    fontFamily: "sans-serif !important",
  },
  formControl: {
    width: "fit-content",
    marginRight: "24px",
  },
  subheading: {
    textAlign: "left",
    fontWeight: "bold",
    color: "#292828d9",
    marginBottom: "24px",
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "16px",
    },
  },
});

class AddTemplate extends React.Component {
  state = {
    initialSearchField: "",
    searchableFields: [],
    fieldsAddedInCsv: [],
    initialCsvField: "",
  };

  fieldSearchChanged = (e, idx) => {
    const { searchableFields, initialSearchField } = this.state;
    const { value } = e.target;

    const tempFields = [...searchableFields];
    if (idx === -1) {
      tempFields.push(initialSearchField);
    } else {
      tempFields[idx] = value;
    }

    this.setState({
      searchableFields: tempFields,
      initialSearchField: "",
    });
  };

  removeSearchField = (e, idx) => {
    const { searchableFields } = this.state;
    const tempFields = [...searchableFields];
    tempFields.splice(idx, 1);
    this.setState({
      searchableFields: tempFields,
    });
  };

  updateInitialsearch = (e) => {
    this.setState({
      initialSearchField: e.target.value,
    });
  };

  csvFieldChanged = (e, idx) => {
    const { fieldsAddedInCsv, initialCsvField } = this.state;
    const { value } = e.target;

    const tempFields = [...fieldsAddedInCsv];
    if (idx === -1) {
      tempFields.push(initialCsvField);
    } else {
      tempFields[idx] = value;
    }

    this.setState({
      fieldsAddedInCsv: tempFields,
      initialCsvField: "",
    });
  };

  removeCsvField = (e, idx) => {
    const { fieldsAddedInCsv } = this.state;
    const tempFields = [...fieldsAddedInCsv];
    tempFields.splice(idx, 1);
    this.setState({
      fieldsAddedInCsv: tempFields,
    });
  };

  updateInitialCsv = (e) => {
    this.setState({
      initialCsvField: e.target.value,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      searchableFields,
      fieldsAddedInCsv,
      initialCsvField,
      initialSearchField,
    } = this.state;
    return (
      <div className={classes.templateContainer}>
        <AppCard
          containerStyle={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ContentHeader
            title="Modify A Template"
            HeaderIcon={RecycleIcon}
            showBackIcon
          />
          <div className={classes.content}>
            <FieldRenderer
              classes={{ container: classes.fieldContainer }}
              field={{ type: FIELD_TYPES.select }}
              label="Select Certificate Template"
            />
            <div className={classes.picklistContainer}>
              <div className={classes.subheading}>
                <CsvFileIcon /> Fields To Be Searched
              </div>
              <div className={classes.fieldsContainer}>
                {searchableFields.map((fld, idx) => (
                  <div className={classes.field}>
                    <FormControl
                      variant="filled"
                      className={classes.formControl}
                    >
                      <FilledInput
                        className={classes.filledInput}
                        hiddenLabel
                        disableUnderline
                        onChange={(e) => this.fieldSearchChanged(e, idx)}
                        value={fld}
                        id="filled-adornment-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {}}
                              onMouseDown={() => {}}
                              edge="end"
                            >
                              <span className={classes.adornIcon}>
                                <RemoveIcon
                                  onClick={(e) =>
                                    this.removeSearchField(e, idx)
                                  }
                                />
                              </span>
                            </IconButton>
                          </InputAdornment>
                        }
                        classes={{
                          input: classes.inputField,
                        }}
                      />
                    </FormControl>
                  </div>
                ))}
                <div className={classes.field}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <FilledInput
                      className={classes.filledInput}
                      hiddenLabel
                      value={initialSearchField}
                      disableUnderline
                      onChange={(e) => this.updateInitialsearch(e)}
                      placeholder="Add Field"
                      id="filled-adornment-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => this.fieldSearchChanged(e, -1)}
                            onMouseDown={() => {}}
                            edge="end"
                          >
                            <span className={classes.adornIcon}>
                              <AddIcon />
                            </span>
                          </IconButton>
                        </InputAdornment>
                      }
                      classes={{
                        input: classes.inputField,
                      }}
                    />
                  </FormControl>
                </div>
              </div>
            </div>

            <div className={classes.picklistContainer}>
              <div className={classes.subheading}>
                <CsvFileIcon /> Fields Added in CSV
              </div>
              <div className={classes.fieldsContainer}>
                {fieldsAddedInCsv.map((fld, idx) => (
                  <div className={classes.field}>
                    <FormControl
                      variant="filled"
                      className={classes.formControl}
                    >
                      <FilledInput
                        className={classes.filledInput}
                        hiddenLabel
                        disableUnderline
                        value={fld}
                        onClick={(e) => this.csvFieldChanged(e, idx)}
                        id="filled-adornment-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onMouseDown={() => {}}
                              onClick={(e) => this.removeCsvField(e, idx)}
                              edge="end"
                            >
                              <span className={classes.adornIcon}>
                                <RemoveIcon />
                              </span>
                            </IconButton>
                          </InputAdornment>
                        }
                        classes={{
                          input: classes.inputField,
                        }}
                      />
                    </FormControl>
                  </div>
                ))}
                <div className={classes.field}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <FilledInput
                      className={classes.filledInput}
                      hiddenLabel
                      disableUnderline
                      placeholder="Add Field"
                      value={initialCsvField}
                      onChange={(e) => this.updateInitialCsv(e)}
                      id="filled-adornment-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={(e) => this.csvFieldChanged(e, -1)}
                            onMouseDown={() => {}}
                            edge="end"
                          >
                            <span className={classes.adornIcon}>
                              <AddIcon />
                            </span>
                          </IconButton>
                        </InputAdornment>
                      }
                      classes={{
                        input: classes.inputField,
                      }}
                    />
                  </FormControl>
                </div>
              </div>
            </div>
            <div className={classes.submitButton}>
              <Button variant="outlined">Add Template</Button>
            </div>
          </div>
        </AppCard>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AddTemplate);
