import React from "react";
import AppCard from "../../components/AppCard";
import "./home.css";
import FieldRenderer from "../../components/FormField";
import { Modal } from "@mui/material";
import { ReactComponent as Logo } from "assets/logo.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import VerifyCertificate from "pages/Certificate/verify";

import { ReactComponent as WatchDoc } from "assets/WatchDoc.svg";
import { Link } from "react-router-dom";
import { IssuerMenuLinks, VerifierMenuLinks } from "./constants";
import { makeStyles } from "@mui/styles";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  modalSize: {
    width: "80%",
    height: "80%",
    backgroundColor: "white",
    margin: "auto",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    height: "64px",
    backgroundColor: "#902a2b",
  },
  modalLogo: {
    height: "48px",
  },
  modalTitle: {
    color: "white",
    fontSize: "18px",
    fontWeight: "bold",
  },
  closeIcon: {
    marginLeft: "auto",
    color: "white",
    cursor: "pointer",
  },
}));

const HomePage = () => {
  const role = "issue";
  const menuLinks = role === "issuer" ? IssuerMenuLinks : VerifierMenuLinks;

  const [modalOpen, setModalOpen] = useState(false);

  const handleLinkClick = (e, menu) => {
    if (menu.clickHandler) {
      if (menu.action === "openVerifyModal") {
        setModalOpen(true);
      }
    }
  };

  const classes = useStyles();

  return (
    <div className="home-container">
      <div className="title">
        <span className="menu-icon">
          <WatchDoc />
        </span>
        <div>{role === "issuer" ? "Bulk Issuer" : "Bulk Verifier"}</div>
      </div>
      <div className="home-cards">
        <AppCard>
          <div className="links-container">
            {menuLinks.map((menu) => (
              <div className="menu-link">
                <Link
                  to={menu.path || ""}
                  onClick={(e) => handleLinkClick(e, menu)}
                >
                  <span className="menu-icon">{menu.icon}</span>
                  <span>{menu.displayName}</span>
                </Link>
              </div>
            ))}
          </div>
        </AppCard>
        <AppCard>
          <div className="right-card">
            <div className="title">
              <span className="menu-icon">
                <WatchDoc />
              </span>
              <div>View and Cancel Or Modify Certificate</div>
            </div>
            <div className="form-container">
              <div className="form-field">
                <FieldRenderer
                  field={{ type: "text_input" }}
                  label="Enter a Certificate ID"
                />
              </div>
              <div className="form-field">
                <FieldRenderer
                  field={{ type: "text_input" }}
                  label="Enter Certificate Type"
                />
              </div>
            </div>
            <div className="submit-btn">
              <Link to="modify-certificate">Submit</Link>
            </div>
          </div>
        </AppCard>
      </div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.modalContainer}>
          <div className={classes.modalSize}>
            <div className={classes.modalHeader}>
              <Logo className={classes.modalLogo} />{" "}
              <span className={classes.modalTitle}>Certificate Portal</span>
              <span className={classes.closeIcon}>
                <CloseOutlinedIcon onClick={() => setModalOpen(false)} />
              </span>
            </div>
            <div className={classes.modalContent}>
              <VerifyCertificate />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HomePage;
