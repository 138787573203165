import AdminHeader from "components/MainHeader/AdminHeader";
import { Navigate } from "react-router-dom";

const UserPrivateRoute = ({ children }) => {
  const token = sessionStorage.getItem("token");
  const role = sessionStorage.getItem("role");

  console.log("role: ", role);

  const isAuthenticated =
    token !== null && token !== undefined && role === "USER";

  if (isAuthenticated) {
    return <>{children}</>;
  } else {
    sessionStorage.clear();
    return <Navigate to="/" replace />;
  }
};

export default UserPrivateRoute;
