
import {
  POST_LOGIN_ERR,POST_LOGIN,GET_COUNT_ADMIN,VERIFY_OTP_ERR,VERIFY_OTP, GET_ALL_USERS_ADMIN ,GET_GRAPH_DATA,GET_FIRST_GRAPH_DATA,GET_COUNT_DATA,GET_STACK_DATA,
  GET_REVOKE_USERS,BLOCK_USER,GET_TOTAL_USER_COUNT,GET_ALL_BLOCKED_USERS,BLOCK_USER_ERR,GET_ALL_USERS,GET_COUNT_VERIFIED
  } from "./types";
  import Swal from "sweetalert2";
  import axios from "axios";
  import { ADMIN_URL } from "utilities/config";

  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err.response.status === 502) {
        window.location.href = "/service-unavailable";
      }
      return Promise.reject(err);
    }
  );

  const errorHandle = () => {
    Swal.fire({
      icon: "error",
      title: "Session Expired!",
      text: "",
      confirmButtonText: "OK",
      confirmButtonColor: "#03596e",
    }).then(function () {
      window.location.replace("/admin");
    });
};
  
  let authtoken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyNTU0ODBmNGE5MTJiNzM3ZjliMTZiNiIsInVzZXJuYW1lIjoiYWRtaW4iLCJyb2xlIjoiYWRtaW4iLCJleHAiOjE2NTI3NjAyMTEsImlhdCI6MTY1Mjc1NjYxMX0.8cKfv0RM1z28vRju8L-G433K8c16yYtcHmc-l3l2HA4";
  

    export const getVerifiedCount = () => (dispatch) => {
      const API_URL = `${ADMIN_URL}ad/dash/getverifiedcount`;  
      axios
        .get(API_URL,{
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("authToken"),
          },
        })
        .then((response) => {
          dispatch({ type: GET_COUNT_VERIFIED, payload: response.data });
        })
        .catch((err) => {
          console.log("Error-> ", err.response);
          if (err?.response?.status === 401) {
            errorHandle();
          }
        });
    };


    export const getAllUsers = (data,data1) => (dispatch) => {
      const API_URL = `${ADMIN_URL}ad/dash/users?pageNo=${data}&size=${data1}`;
      axios
        .get(API_URL, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("authToken"),
          },
        })
        .then((res) => res.data)
        .then((response) => {
          console.log("200 - ", response);
          dispatch({ type: GET_ALL_USERS, payload: response });
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.status === 401) {
            errorHandle();
          }
        });
    };

    export const getAllBlockedUsers = (data,data1) => (dispatch) => {
      const API_URL = `${ADMIN_URL}ad/dash/get_blocked_users?pageNo=${data}&size=${data1}`;
      axios
        .get(API_URL, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("authToken"),
          },
        })
        .then((res) => res.data)
        .then((response) => {
          console.log("200 - ", response);
          // if(response.status==200){
          dispatch({ type: GET_ALL_BLOCKED_USERS, payload: response });
          // }
        })
        .catch((err) => {
          dispatch({ type: BLOCK_USER_ERR, payload: err });
          console.log(err);
          if (err?.response?.status === 401) {
            errorHandle();
          }
        });
    };

    export const getTotalNumberUsers = () => (dispatch) => {
      const API_URL = `${ADMIN_URL}ad/dash/get_counts`;
      axios
        .get(API_URL, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("authToken"),
          },
        })
        .then((res) => res.data)
        .then((response) => {
          console.log("200 - ", response);
          // if(response.status==200){
          dispatch({ type: GET_TOTAL_USER_COUNT, payload: response });
          // }
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.status === 401) {
            errorHandle();
          }
        });
    };
    
    export const blockUser = (id,data) => (dispatch) => {
      let API_URL=''
      if(data == 'block'){
        API_URL = `${ADMIN_URL}ad/dash/blockuser`;
      }else{
        API_URL = `${ADMIN_URL}ad/dash/approveuser`;
      }
      axios
        .post(
          API_URL,
          { id: id },
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("authToken"),
            },
          }
        )
        .then((res) => res.data)
        .then((response) => {
          console.log("200 - ", response);
          // if(response.status==200){
          dispatch({ type: BLOCK_USER, payload: response });
          // }
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.status === 401) {
            errorHandle();
          }
        });
    };

    export const getRevokedUsers = () => (dispatch) => {
      const API_URL = `${ADMIN_URL}ad/dash/get_revoked_users`;
      axios
        .get(API_URL, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("authToken"),
          },
        })
        .then((res) => res.data)
        .then((response) => {
          console.log("200 - ", response);
          dispatch({ type: GET_REVOKE_USERS, payload: response });
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.status === 401) {
            errorHandle();
          }
        });
    };





  
  export const user_login = (email,pswd) => (dispatch) => {
  
    const API_URL = `${ADMIN_URL}ad/login`; 

    const body = {
      email: email,
      password: pswd,
    };
  
    console.log("=====>>", body);
  
    axios
      .post(API_URL, body)
      .then((res) => {
        dispatch({ type: POST_LOGIN, payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: POST_LOGIN_ERR, payload: err.response.data });
      });
  };
 

  export const verify_otp_func = (email, otp) => (dispatch) => {
    const API_URL = `${ADMIN_URL}ad/verifyotp`;

    const body = {
      email: email,
      otp: otp,
    };
  
    console.log("=====>>", body);
  
    axios
      .post(API_URL, body)
      .then((res) => {
        dispatch({ type: VERIFY_OTP, payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: VERIFY_OTP_ERR, payload: err.response.data });
      });
  };

  export const getAllUserAdmin = () => (dispatch) => {
    const API_URL = `${ADMIN_URL}ad/dash/users`;  
    axios
      .get(API_URL,{
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("authToken"),
        },
      })
      .then((response) => {
        dispatch({ type: GET_ALL_USERS_ADMIN, payload: response.data });
      })
      .catch((err) => {
        console.log("Error-> ", err.response);
        // if(sessionStorage.getItem("authToken") !== null){
          if (err?.response?.status === 401) {
            errorHandle();
          }
        // }else{
        //   dispatch({ type: NOT_REDIRECT, payload: "notRedirect"})
        // }
      });
  };


  export const getCountAll = () => (dispatch) => {
    const API_URL = `${ADMIN_URL}ad/dash/getcount`;  
    axios
      .get(API_URL,{
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("authToken"),
        },
      })
      .then((response) => {
        dispatch({ type: GET_COUNT_ADMIN, payload: response.data });
      })
      .catch((err) => {
        console.log("Error-> ", err.response);
        if (err?.response?.status === 401) {
          errorHandle();
        }
      });
  };

  export const getGraphData = (data,data1) => (dispatch) => {
    const API_URL = `${ADMIN_URL}ad/dash/fetchcount`;  
    axios
      .get(API_URL,{
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("authToken"),
        },
      })
      .then((response) => {
        dispatch({ type: GET_GRAPH_DATA, payload: response.data });
      })
      .catch((err) => {
        console.log("Error-> ", err.response);
        if (err?.response?.status === 401) {
          errorHandle();
        }
      });
  };

  export const getFirstGraphData = () => (dispatch) => {
    const API_URL = `${ADMIN_URL}ad/dash/sharecout`;  
    axios
      .get(API_URL,{
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("authToken"),
        },
      })
      .then((response) => {
        console.log(response)
        dispatch({ type: GET_FIRST_GRAPH_DATA, payload: response.data });
      })
      .catch((err) => {
        console.log("Error-> ", err.response);
        // if (err?.response?.status === 401) {
        //   errorHandle();
        // }
      });
  };

  export const getCountCertificate = () => (dispatch) => {
    const API_URL = `${ADMIN_URL}ad/dash/getallcount`;  
    axios
      .get(API_URL,{
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("authToken"),
        },
      })
      .then((response) => {
        console.log(response)
        dispatch({ type: GET_COUNT_DATA, payload: response.data });
      })
      .catch((err) => {
        console.log("Error-> ", err.response);
        if (err?.response?.status === 401) {
          errorHandle();
        }
      });
  };

export const getstackholderDetail = () => (dispatch) => {
  const API_URL = `${ADMIN_URL}ad/dash/stakeholders`;  
  axios
    .get(API_URL,{
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("authToken"),
      },
    })
    .then((response) => {
      dispatch({ type: GET_STACK_DATA, payload: response.data });
    })
    .catch((err) => {
      console.log("Error-> ", err.response);
      if (err?.response?.status === 401) {
        errorHandle();
      }
    });
};