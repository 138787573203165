import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import AppCard from "components/AppCard";
import HeaderTabs from "components/HeaderTabs";
import axios from "axios";
import { Buffer } from "buffer";
import { pdfjs } from "react-pdf";
import "../../../style.css";
import Modal from "react-modal";
import verify from "../../../assets/verifiedg.svg";
import mismatch from "../../../assets/mismatch.png";
import MainHeader from "components/MainHeader";
import { BASE_URL } from "utilities/config";
import { concurrencyLoginData } from "store/action";
import { HiDownload } from "react-icons/hi";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "80%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 3px 20px #377D2221",
  },
};

const useStyles = makeStyles((theme) => ({
  templateContainer: {
    width: "80%",
    height: "80%",
    margin: "auto",
    marginBottom: "50px",
  },
  detailsHeader: {},
  content: {
    padding: "40px",
    flex: 1,
  },
  details: {
    display: "flex",
  },
  transDetails: {
    fontSize: "20px",
    textAlign: "left",
    fontWeight: "bold",
  },
  certDetails: {
    lineHeight: "50px",
    fontSize: "18px",
    fontWeight: "bold",
    marginTop: "15px",
    marginLeft: "-6px",
    textAlign: "left",
  },
  verifyImg: {
    "& img": {
      width: "60px",
    },
    marginRight: "12px",
  },
  transactionId: {
    color: "#902a2b",
    fontSize: "16px",
    fontWeight: 500,
  },
  certId: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: "20px",
  },
  imageView: {
    // marginLeft: "-6%",
    // marginTop: "80px",
  },
  detailView: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "32px",
  },
  tableView: {
    flex: 1,
    marginTop: "60px",
    "& .MuiDataGrid-root": {
      height: "375px",
    },
  },
  footerBtns: {
    "& button": {
      borderRadius: "16px",
    },
    display: "flex",
    justifyContent: "right",
    marginTop: "20px",
  },
  backBtn: {
    marginRight: "16px",
    "& button": {
      color: "#656565",
      borderColor: "#656565",
    },
  },
}));

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CertificateDetails = () => {
  const classes = useStyles();
  const [certData, getCertificate] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [forterLoop, setTerLoop] = useState(true);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [View, setView] = useState(false);
  const [loader, setLoader] = useState(true);
  let subtitle;

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  const certificateData = {
    id: 233213,
    org: "Nambikkai Inaiyam",
    transactionId: "23er445566667re4",
  };
  const [selectedTab, setSelectedTab] = useState(0);

  // redirection
  const certificateDetails = JSON.parse(
    localStorage.getItem("certificate_details")
  );

  let id = "";
  let name = "";
  let mail = "";
  let type = "";
  if (certificateDetails !== null) {
    id = certificateDetails.id;
    name = certificateDetails.name;
    mail = certificateDetails.mail;
    type = certificateDetails.type;
  }
  // const { id, name, mail, type } = certificateData;

  // var Id = location.state.id;
  var Id = id;
  let buffId = Buffer.from(Id, "base64");
  Id = buffId.toString("ascii");

  let newType = type;
  let bufftype = Buffer.from(newType, "base64");
  newType = bufftype.toString("utf8");
  const documentType = newType.split(":");
  // console.log("document type : ", documentType);

  const body = {
    id: Id,
    name: name,
    mail: mail,
    linktype: type,
    // name: location.state.name,
    // mail: location.state.mail,
    // linktype: location.state.type,
  };

  useEffect(() => {
    /* AXIOS INTERCEPTOR */
    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        if (err.response.status === 502) {
          window.location.href = "/service-unavailable";
        }
        return Promise.reject(err);
      }
    );
    axios
      .post(`${BASE_URL}cportal/public/getcertdata`, body, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        setLoader((prev) => !prev);

        if (response.data.message == "Data fetched successfully") {
          const cert_data = response.data.data;
          getCertificate(cert_data);
        }
      })
      .catch((err) => {
        setLoader((prev) => !prev);

        if (err.response.status === 401) {
          concurrencyLoginData();
        }
      });
  }, []);

  if (forterLoop === true) {
    // axios
    //   .post(`${BASE_URL}cportal/public/getcertdata`, body, {
    //     headers: {
    //       Authorization: "Bearer " + sessionStorage.getItem("token"),
    //     },
    //   })
    //   .then((response) => {
    //
    //     if (response.data.message == "Data fetched successfully") {
    //       const cert_data = response.data.data;
    //       getCertificate(cert_data);
    //     }
    //   })
    //   .catch((err) => {
    //
    //   });
    // setTerLoop(false);
  }

  const ViewMore = () => {
    setView(true);
  };
  const ViewLess = () => {
    setView(false);
  };
  //
  const url = certData?.certificate?.data;
  //


  console.log(documentType[1])
  console.log(certData)
  return (
    <div className="d-flex flex-column gap-4">
      <MainHeader className="" />
      <div className={classes.templateContainer}>
        <AppCard
          containerStyle={{ width: "100%", height: "auto", borderRadius: 0 }}
        >
          <div className={classes.detailsHeader}>
            <HeaderTabs
              tabs={["Certificate", "Certificate Data"]}
              onChange={(idx) => {
                setSelectedTab(idx);
              }}
              selectedTab={selectedTab}
            />
          </div>
          <div className={classes.content}>
            {loader === true && (
              <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                <div class="loader"></div>
              </div>
            )}
            {loader === false && (
              <>
                <div className={classes.imageContainer}>
                  <div className={classes.details}>
                    <div className={classes.verifyImg}>
                      {url !== undefined && certData !== "" && (
                        <>
                          {/* <img
                            src={verify}
                            alt="img"
                            style={{ marginTop: "15px", width: "100px" }}
                          /> */}
                          {certData.verificationResponse.status === true && (
                            <img
                              src={verify}
                              alt="img"
                              style={{ marginTop: "15px", width: "100px" }}
                            />
                          )}
                          {certData.verificationResponse.status === false && (
                            <img
                              src={mismatch}
                              alt="img"
                              style={{ marginTop: "15px", width: "100px" }}
                            />
                          )}
                        </>
                      )}
                    </div>
                    {certData !== "" && (
                      <div>
                        {certData.certificatedata.CertificateData ===
                        undefined ? (
                          <div className={classes.certDetails} id="enter">
                            This Certificate ID{" "}
                            <span
                              style={{ color: "#377A21" }}
                              className={classes.certId}
                            >
                              {" "}
                              {certData.certificatedata.CERTIFICATENO}{" "}
                            </span>
                            is Verified by {certificateData.org}.
                          </div>
                        ) : (
                          <div className={classes.certDetails} id="enter">
                            This Certificate ID{" "}
                            <span
                              style={{ color: "#377A21" }}
                              className={classes.certId}
                            >
                              {documentType[1] !== "dme"
                                ? certData.certificatedata.issuedTo.person.roll
                                : certData.certificatedata.number}{" "}
                            </span>
                            is Verified by {certificateData.org}.
                          </div>
                        )}
                        {View == true && (
                          <div className={classes.transDetails} id="enter">
                            <span>Transaction ID:</span>{" "}
                            <span className={classes.transactionId}>
                              {certData.txnId}
                            </span>
                          </div>
                        )}
                        {View == false ? (
                          <div
                            style={{
                              marginLeft: "-61%",
                              marginTop: "10px",
                              color: "rgb(144, 42, 43)",
                              fontWeight: "bold",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            <a onClick={ViewMore}>
                              Click here to view Blockchain details
                            </a>
                          </div>
                        ) : (
                          <div
                            style={{
                              marginLeft: "-93%",
                              color: "rgb(144, 42, 43)",
                              fontWeight: "bold",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            <a onClick={ViewLess}>View less</a>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {selectedTab === 0 ? (
                    url === undefined ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          width: "100%",

                          height: "120px",
                          color: "#000000",
                          fontWeight: "500",
                          fontSize: "20px",
                        }}
                      >
                        Your certificate has not been issued yet.
                      </div>
                    ) : (
                      <div className="d-flex flex-column gap-3">
                        <div className={classes.imageView}>
                          <embed
                            src={`data:application/pdf;base64,${url}`}
                            height={800}
                            width={500}
                          />
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          <a
                            className="d-flex justify-content-center align-items-center gap-2"
                            style={{
                              backgroundColor: "#902A2B",
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "#FFFFFF",
                              border: "none",
                              borderRadius: "25px",
                              width: "120px",
                              height: "30px",
                              textDecoration: "none",
                            }}
                            href={`data:application/pdf;base64,${url}`}
                            download
                          >
                            <HiDownload style={{ fontSize: "20px" }} />
                            Download
                          </a>
                        </div>
                      </div>
                    )
                  ) : (
                    <>
                      {certData === "" ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            width: "100%",

                            height: "120px",
                            color: "#000000",
                            fontWeight: "500",
                            fontSize: "20px",
                          }}
                        >
                          Your certificate has not been issued yet.
                        </div>
                      ) : (
                        <div>
                          {documentType[1] !== "dme" &&
                          certData?.certificatedata?.Certificate?.CertificateData ===
                            undefined ? (
                            <div style={{ marginLeft: "4%" }}>
                              <div
                                className="row"
                                style={{ display: "flex", marginTop: "5%" }}
                              >
                                {certData.certificatedata.APPLICANTNAME !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Name
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                      >
                                        :{" "}
                                        {certData.certificatedata.APPLICANTNAME}
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {certData.certificatedata.PASSINGYEAR !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Year of Passing
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                      >
                                        : {certData.certificatedata.PASSINGYEAR}
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {certData.certificatedata.COURSECOMPLETION !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Course Completion{" "}
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                      >
                                        :{" "}
                                        {
                                          certData.certificatedata
                                            .COURSECOMPLETION
                                        }
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {certData.certificatedata.CURRENTCOURSE !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Current Course
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                      >
                                        :{" "}
                                        {certData.certificatedata.CURRENTCOURSE}
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {certData.certificatedata.DATEOFISSUE !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Date Of Issue
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                      >
                                        : {certData.certificatedata.DATEOFISSUE}
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {certData.certificatedata.AADHARNO !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Aadhaar
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                          color: "#505050",
                                        }}
                                      >
                                        : {certData.certificatedata.AADHARNO}
                                      </a>
                                    </div>
                                  </div>
                                )}
                                {certData.certificatedata.DISTRICT !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        District
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                      >
                                        : {certData.certificatedata.DISTRICT}
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {certData.certificatedata.ACADEMICYEAR !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Academic Year
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                      >
                                        :{" "}
                                        {certData.certificatedata.ACADEMICYEAR}
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {certData.certificatedata.CERTIFICATENO !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Certificate No
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                      >
                                        :{" "}
                                        {certData.certificatedata.CERTIFICATENO}
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {certData.certificatedata.FATHERHUSNAME !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Father name
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                      >
                                        :{" "}
                                        {certData.certificatedata.FATHERHUSNAME}
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {certData.certificatedata.INSTNAME !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Inst. Name
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                      >
                                        : {certData.certificatedata.INSTNAME}
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {certData.certificatedata.INSTADDRESS !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Inst Address
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                      >
                                        : {certData.certificatedata.INSTADDRESS}
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {certData.certificatedata.PINCODE !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Pincode
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                      >
                                        : {certData.certificatedata.PINCODE}
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {certData.certificatedata.SERVICENAME !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Service Name
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                      >
                                        : {certData.certificatedata.SERVICENAME}
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {certData.certificatedata.TALUK !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Taluk
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                      >
                                        : {certData.certificatedata.TALUK}
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {certData.certificatedata.VILLTOWN !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        VillTown
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                      >
                                        : {certData.certificatedata.VILLTOWN}
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {certData.certificatedata.ADDRESS !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Address
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                      >
                                        : {certData.certificatedata.ADDRESS}
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {certData.certificatedata.ISSUINGAUTHORITY !==
                                  undefined && (
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Issuing Authority
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                      >
                                        :{" "}
                                        {
                                          certData.certificatedata
                                            .ISSUINGAUTHORITY
                                        }
                                      </a>
                                    </div>
                                  </div>
                                )}

                                <div
                                  className="col-md-6"
                                  style={{ display: "flex" }}
                                ></div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              {documentType[1] === "dme" ? (
                                <div
                                  className="row"
                                  style={{
                                    display: "flex",
                                    marginTop: "5%",
                                    marginLeft: "5%",
                                  }}
                                >
                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        id="enter"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Name
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                        id="enter"
                                      >
                                        :{" "}
                                        {
                                          certData.certificatedata.IssuedTo
                                            .Person.name
                                        }
                                      </a>
                                    </div>
                                  </div>

                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        id="enter"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Date Of Birth
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                        id="enter"
                                      >
                                        :{" "}
                                        {
                                          certData.certificatedata.IssuedTo
                                            .Person.dob
                                        }
                                      </a>
                                    </div>
                                  </div>

                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        id="enter"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Course
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                        id="enter"
                                      >
                                        :{" "}
                                        {
                                          certData.certificatedata
                                            .CertificateData.Course.name
                                        }
                                      </a>
                                    </div>
                                  </div>

                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        id="enter"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Year Of Passing
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                        id="enter"
                                      >
                                        :{" "}
                                        {
                                          certData.certificatedata
                                            .CertificateData.Examination.year
                                        }
                                      </a>
                                    </div>
                                  </div>

                                  <div
                                    className="col-md-6"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="col-md-4"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <label
                                        id="enter"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "#292828",
                                        }}
                                      >
                                        Total Marks
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-8"
                                      id="enter"
                                      style={{ textAlign: "left" }}
                                    >
                                      <a
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          color: "#505050",
                                        }}
                                        id="enter"
                                      >
                                        :{" "}
                                        {
                                          certData.certificatedata
                                            .CertificateData.Performance
                                            .marksTotal
                                        }
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="row"
                                  style={{
                                    display: "flex",
                                    marginTop: "5%",
                                    marginLeft: "5%",
                                  }}
                                >
                                  {certData?.certificatedata?.Certificate?.issuedTo?.person
                                    .name !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          id="enter"
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Name
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                          id="enter"
                                        >
                                          :
                                          {
                                            certData?.certificatedata?.Certificate?.issuedTo?.person?.name
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}
                                  {certData?.certificatedata?.Certificate?.issuedTo?.person?.roll !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Roll No
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata?.Certificate?.issuedTo?.person?.roll
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.issuedTo?.person?.class !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Class
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata?.Certificate?.issuedTo?.person?.class
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.issuedTo?.person
                                    .dob !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Date of Birth
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata?.Certificate?.issuedTo?.person?.dob
                                          }{" "}
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.issuedBy?.organization?.name !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Total marks in word
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata
                                              ?.Certificate?.CertificateData?.performance
                                              ?.marksTotalWords
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.issuedBy
                                    ?.organization?.tin !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Organization Tin
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata?.Certificate?.issuedBy
                                              ?.organization?.tin
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.issuedBy
                                    ?.organization?.type !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Organization Type
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata?.Certificate?.issuedBy
                                              ?.organization?.type
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.issuedBy
                                    ?.organization?.uid !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Organization uid
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "600",
                                            fontSize: "16px",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata?.Certificate?.issuedBy
                                              ?.organization?.uid
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.CertificateData
                                    ?.examination.name !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Examination Name
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata
                                              ?.Certificate?.CertificateData?.examination?.name
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.CertificateData
                                    ?.examination?.month !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Examination Month
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata
                                              ?.Certificate?.CertificateData?.examination?.month
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.CertificateData
                                    ?.examination?.session !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Examination session
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata
                                              ?.Certificate?.CertificateData?.examination
                                              ?.ssession
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.CertificateData
                                    ?.examination?.type !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Examination type
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata
                                              ?.Certificate?.CertificateData?.examination?.type
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.CertificateData
                                    ?.examination?.year !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Examination year
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata
                                              ?.Certificate?.CertificateData?.examination?.year
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.CertificateData?.info
                                    ?.concession !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Concession
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata
                                              ?.Certificate?.CertificateData?.info?.concession
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.CertificateData?.info
                                    ?.groupCode !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Group Code
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata
                                              ?.Certificate?.CertificateData?.info?.groupCode
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.CertificateData?.info
                                    ?.groupName !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Group Name
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata
                                              ?.Certificate?.CertificateData?.info?.groupName
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.CertificateData?.info
                                    ?.revdt !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Revdt
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata
                                              ?.CertificateData?.info?.revdt
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.CertificateData?.info
                                    .tmrCode !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Tmr Code
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata
                                              ?.Certificate?.CertificateData?.info?.tmrCode
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.CertificateData?.info
                                    ?.tmrDate !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Tmr Date
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata
                                              ?.Certificate?.CertificateData?.info?.tmrDate
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.CertificateData
                                    ?.school?.name !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Result
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata
                                              ?.Certificate?.CertificateData?.performance
                                              ?.result
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.CertificateData
                                    ?.school?.medium !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          School Medium
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata
                                              ?.Certificate?.CertificateData?.school?.medium
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.CertificateData
                                    ?.school?.code !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          School Code
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata
                                              ?.Certificate?.CertificateData?.code
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.CertificateData
                                    ?.performance?.marksTotal !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Total Marks
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata
                                              ?.Certificate?.CertificateData?.performance
                                              ?.marksTotal
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.CertificateData
                                    ?.performance?.marksTotalWords !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          Organization Name
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata?.Certificate?.issuedBy
                                              ?.organization?.name
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {certData?.certificatedata?.Certificate?.CertificateData
                                    ?.performance?.result !== "" && (
                                    <div
                                      className="col-md-6"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        className="col-md-4"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#292828",
                                          }}
                                        >
                                          School Name
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        id="enter"
                                        style={{ textAlign: "left" }}
                                      >
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                            color: "#505050",
                                          }}
                                        >
                                          :{" "}
                                          {
                                            certData?.certificatedata
                                              ?.Certificate?.CertificateData?.school?.name
                                          }
                                        </a>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                              <br />
                              <br />
                              {documentType[1] !== "dme" &&
                                certData?.certificatedata?.CertificateData
                                  ?.performance?.subjects !== undefined && (
                                  <>
                                    <div className="row" id="tableHead">
                                      <div className="col-md-2">
                                        <a
                                          style={{
                                            color: "black",
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                          }}
                                        >
                                          Name
                                        </a>
                                      </div>
                                      <div className="col-md-2">
                                        <a
                                          style={{
                                            color: "black",
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                          }}
                                        >
                                          Internal Marks
                                        </a>
                                      </div>
                                      <div className="col-md-2">
                                        <a
                                          style={{
                                            color: "black",
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                          }}
                                        >
                                          Practical Marks
                                        </a>
                                      </div>
                                      <div className="col-md-2">
                                        <a
                                          style={{
                                            color: "black",
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                          }}
                                        >
                                          Theory Marks
                                        </a>
                                      </div>
                                      <div className="col-md-2">
                                        <a
                                          style={{
                                            color: "black",
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                          }}
                                        >
                                          Total Marks
                                        </a>
                                      </div>
                                      <div className="col-md-2">
                                        <a
                                          style={{
                                            color: "black",
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                          }}
                                        >
                                          Total Marks in word
                                        </a>
                                      </div>
                                    </div>
                                    {certData?.certificatedata?.CertificateData?.performance?.subjects.map(
                                      (item, indx) => (
                                        <>
                                          <div
                                            className="row"
                                            style={{
                                              height: "71px",
                                              backgroundColor:
                                                indx % 2 !== 0
                                                  ? "#902A2B07"
                                                  : "",
                                              padding: "10px",
                                            }}
                                          >
                                            <div className="col-md-2">
                                              <a style={{ fontSize: "16px" }}>
                                                {item.name}
                                              </a>
                                            </div>
                                            <div className="col-md-2">
                                              <a style={{ fontSize: "16px" }}>
                                                {item.marksInternal}
                                              </a>
                                            </div>
                                            <div className="col-md-2">
                                              <a style={{ fontSize: "16px" }}>
                                                {item.marksPractical}
                                              </a>
                                            </div>
                                            <div className="col-md-2">
                                              <a style={{ fontSize: "16px" }}>
                                                {item.marksTheory}
                                              </a>
                                            </div>
                                            <div className="col-md-2">
                                              <a style={{ fontSize: "16px" }}>
                                                {item.marksTotal}
                                              </a>
                                            </div>
                                            <div className="col-md-2">
                                              <a style={{ fontSize: "14px" }}>
                                                {item.marksWords}
                                              </a>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    )}
                                  </>
                                )}
                            </div>
                          )}
                        </div>
                      )}
                      <div style={{ marginTop: "20px" }}></div>
                    </>
                  )}
                </div>

                {/*  */}
                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col-md-6 offset-md-6">
                    <div className="flex"></div>
                  </div>
                </div>
              </>
            )}
          </div>
        </AppCard>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div>
            <a
              style={{
                fontWeight: "bold",
                fontSize: "30px",
                cursor: "pointer",
                textAlign: "right",
                marginLeft: "95%",
                color: "#902A2B",
              }}
              onClick={() => setIsOpen(false)}
            >
              X
            </a>
          </div>
          <div>
            <object
              width="100%"
              height="500"
              data={url}
              type="application/pdf"
            ></object>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default CertificateDetails;
