import React, {useState} from "react";
import cross from "../../../assets/Group 635.png"
import footLogo from "../../../assets/Nambikkai iniyam logo.png"
import '../../../style.css'

const LinkExpired = (props) => {


  return (
    <div style={{backgroundColor: '#00000036',width:'100%',height:'107%'}}>
        <div style={{height:'50%',width:'40%',backgroundColor:'white',marginTop:'10%',marginLeft:'30%',borderRadius:'5px'}}>
          <div>
            <img src={cross} style={{width:'80px',marginTop:'20px'}} />
            <br/>
            <p id="enter" style={{color:'#902A2B',marginTop:'20px',fontWeight:'bold'}}>Link Has been expired.</p>
            <p id="enter" style={{width:'76%',marginLeft:'11%',color:'#292828D8',fontWeight:'bold',marginTop:'40px'}}>This shared link for verification has been expired, Kindly use valid link for verification.</p>
            <p id="enter" style={{display:'flex',marginLeft:'37%',marginTop:'50px'}}><img style={{width:'10px',height:'10px'}} src={footLogo} /><p style={{color:'#4984CA',fontSize:'10px',marginLeft:'5px'}}>Powered By Nambikkai Inaiyam</p></p>
          </div>
        </div>
      
    </div>
  );
};

export default LinkExpired;
