import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Provider } from "react-redux";
// import { store } from './store';
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));

setTimeout(
  () =>
    root.render(
      <Provider store={store}>
        <App />
      </Provider>
    ),
  1700
);
reportWebVitals();

// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

///////

// the show/hide functions are passed as props
// ReactDOM.render(
//   <Provider store={store}>
//     {/* <PersistGate persistor={persistor}> */}
//     <App />
//     {/* </PersistGate> */}
//   </Provider>,
//   document.getElementById("root")
