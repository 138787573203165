import React from "react";
import "./tabs.css";

class HeaderTabs extends React.Component {
  render() {
    const { tabs, onChange, selectedTab } = this.props;
    return (
      <div className="details-header">
        {tabs.map((tab, idx) => (
          <div
            className={`${idx === selectedTab ? "selected" : ""} details-tab`}
            onClick={() => {
              onChange(idx);
            }}
            style={{ left: `${71 + 255 * idx}px` }}
          >
            {tab}
          </div>
        ))}
      </div>
    );
  }
}

export default HeaderTabs;
