import React from "react";
import { withStyles } from "@mui/styles";
import FieldRenderer, { FIELD_TYPES } from "components/FormField";
import AppCard from "components/AppCard";
import { FileDropComponent } from "components/FileDrop";
import { ReactComponent as CsvDocIcon } from "assets/CsvDoc.svg";
import { ReactComponent as CsvDownload } from "assets/CsvDownload.svg";
import { Button } from "@mui/material";
import ContentHeader from "components/ContentHeader";
import { LibraryAdd } from "@mui/icons-material";

const styles = (theme) => ({
  container: {
    width: "80%",
    height: "80%",
    margin: "auto",
  },
  header: {
    height: "60px",
    textAlign: "left",
  },
  content: {
    width: "80%",
    display: "flex",
    height: "calc(100% - 150px)",
    margin: "auto",
    justifyContent: "space-between",
    marginTop: "40px",
  },
  left: {
    width: "45%",
    display: "flex",
    flexDirection: "column",
  },
  right: {
    width: "45%",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    margin: "12% 0 8% 0",
    textAlign: "left",
  },
  combimedIcons: {},
  downloadIcon: {},
  csvIcon: {},
  downloadBox: {
    border: `2px dashed  #902A2B4D`,
    padding: "10%",
    width: "90%",
    boxShadow: "0px 3px 6px #377D2221",
    borderRadius: "5px",
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  downloadAdjustments: {
    // height: 'calc(100% - 40px)',
    // margin: 'auto',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center'
  },
  downloadBtn: {
    color: `${theme.palette.primary.main} !important`,
    borderRadius: "6px !important",
    lineHeight: "normal !important",
    width: "fit-content",
    // marginTop: '28px !important',
  },
  uploadBtn: {
    color: `${theme.palette.text.white} !important`,
    background: `${theme.palette.primary.main} !important`,
    borderRadius: "6px",
    lineHeight: "normal !important",
    // marginTop: '28px !important'
  },
});

class BulkUpload extends React.Component {
  handleChange(e) {}
  goBack = () => {
    this.context.router.goBack();
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <AppCard containerStyle={{ width: "100%", height: "100%" }}>
          <div className={classes.header}>
            <ContentHeader
              title="Bulk Upload"
              showBackIcon
              HeaderIcon={LibraryAdd}
              handleBackButton={this.goBack}
            />
          </div>
          <div className={classes.content}>
            <div className={classes.left}>
              <FieldRenderer
                field={{ type: FIELD_TYPES.select }}
                label="Select Certificate Type"
              />
              <div className={classes.title}>
                View Or Download Template Here:
              </div>
              <div className={classes.downloadBox}>
                <div className={classes.downloadAdjustments}>
                  {/* <div className={classes.combimedIcons}>
										<span className={classes.csvIcon}>
											<CsvDocIcon />
										</span>
										<span className={classes.downloadIcon}>
											<DownloadIcon />
										</span>
									</div> */}
                  <span className={classes.csvDownload}>
                    <CsvDownload />
                  </span>
                </div>
                <Button variant="outlined" className={classes.downloadBtn}>
                  Download
                </Button>
              </div>
            </div>
            <div className={classes.right}>
              <FieldRenderer
                field={{ type: FIELD_TYPES.textInput }}
                label="Enter Year"
              />
              <div className={classes.title}>Upload CSV Here:</div>
              <div className={classes.downloadBox}>
                <FileDropComponent
                  styles={{ height: "calc(100% - 40px)", width: "100%" }}
                >
                  <div className={classes.combimedIcons}>
                    <span className={classes.csvIcon}>
                      <CsvDocIcon />
                    </span>
                    {/* <span className={classes.downloadIcon}>
											<UploadIcon />
										</span> */}
                  </div>
                </FileDropComponent>
                <Button variant="filled" className={classes.uploadBtn}>
                  Upload File
                </Button>
              </div>
            </div>
          </div>
        </AppCard>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(BulkUpload);
