import './card.css';
import React, { Component } from 'react';

class AppCard extends Component {
	render() {
		const { children, containerStyle } = this.props;
		return (
			<div className="card-container" style={containerStyle}>
				{children}
			</div>
		);
	}
}

export default AppCard;
