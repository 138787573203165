import AdminHeader from "components/MainHeader/AdminHeader";
import { Navigate } from "react-router-dom";

const AdminPrivateRoute = ({ children }) => {
  const token = sessionStorage.getItem("token");
  const role = sessionStorage.getItem("role");

  console.log("role: ", role);

  const isAuthenticated =
    token !== null && token !== undefined && role === "ADMIN";

  if (isAuthenticated) {
    return (
      <div className="w-100">
        <AdminHeader />
        {children}
      </div>
    );
  } else {
    sessionStorage.clear();
    return <Navigate to="/" replace />;
  }
};

export default AdminPrivateRoute;
